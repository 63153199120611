import React from "react";
import useContextMenu from "../../../../../hooks/useContextMenu";
import { ChatMessagesEmpty } from "../../ChatMessagesList/ChatMessagesEmpty";
import { ChatMessagesList } from "../../ChatMessagesList/ChatMessagesList";
import { useAppDispatch, useAppSelector } from "../../../../../store";
import {
    selectChatLoading,
    selectMessages,
    selectMessagesLoading,
    selectMessagesNextPage,
    selectThread,
} from "../../../../../store/slice/chat/chatSlice";
import { useRef } from "react";
import { getNextMessages } from "../../../../../store/slice/chat/thunks";
import { MessageContextMenu } from "./MessageContextMenu";

export const MessagesContainer = () => {
    const dispatch = useAppDispatch();
    const messagesLoading = useAppSelector(selectMessagesLoading);
    const threadsLoading = useAppSelector(selectChatLoading);
    const thread = useAppSelector(selectThread);
    const messages = useAppSelector(selectMessages);
    const nextPage = useAppSelector(selectMessagesNextPage);

    const contextMenuRef = useRef(null);
    const { clicked, setClicked, selectedEntity: selectedMessage, setSelectedEntity, points, setPoints } = useContextMenu<any>(contextMenuRef);

    if (!messages.length || messagesLoading || !thread || threadsLoading) {
        return (
            <ChatMessagesEmpty
                hasMessages={!!messages.length}
                messagesLoading={messagesLoading}
                hasThread={!!thread}
                threadsLoading={threadsLoading}
            />
        )
    }

    const handleContextMenu = (event: any, entity: any) => {
        event.preventDefault();
        setClicked(true);
        setPoints(event);
        setSelectedEntity(entity);
    };

    const handleLoadMore = () => {
        dispatch(getNextMessages({ threadId: thread?.id, nextPage }));
    };

    return (
        <>
            <ChatMessagesList
                messages={messages}
                handleContextMenu={handleContextMenu}
                selectedMessage={selectedMessage}
                loadMore={handleLoadMore}
                hasMore={nextPage}
            />
            <MessageContextMenu isToggled={clicked} menuRef={contextMenuRef} message={selectedMessage} points={points} />
        </>
    )
}