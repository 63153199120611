import * as React from 'react';

const DiagramSvg = (props) => (
  <svg
    width={25}
    height={25}
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M11.815 15.755c-.11.214-.11.494-.11 1.054v1.8c0 .56 0 .84.11 1.054a1 1 0 0 0 .437.438c.213.108.494.108 1.054.108h1.8c.56 0 .84 0 1.054-.108a1 1 0 0 0 .437-.438c.109-.213.109-.494.109-1.053v-1.8c0-.56 0-.84-.11-1.054a.999.999 0 0 0-.436-.438c-.214-.108-.494-.108-1.054-.108h-1.8c-.56 0-.84 0-1.054.108a1 1 0 0 0-.437.438Zm0 0-3.37-5.871m.26-2.175h8m-11.4 2.5h1.8c.56 0 .84 0 1.055-.108a1 1 0 0 0 .437-.438c.109-.213.109-.493.109-1.054v-1.8c0-.56 0-.84-.11-1.054a1 1 0 0 0-.436-.437c-.214-.109-.494-.109-1.054-.109h-1.8c-.56 0-.84 0-1.054.11a1 1 0 0 0-.437.436c-.11.214-.11.494-.11 1.054v1.8c0 .56 0 .84.11 1.054a1 1 0 0 0 .437.438c.214.108.494.108 1.054.108Zm13 0h1.8c.56 0 .84 0 1.055-.108a1 1 0 0 0 .437-.438c.109-.213.109-.493.109-1.054v-1.8c0-.56 0-.84-.11-1.054a1 1 0 0 0-.436-.437c-.214-.109-.494-.109-1.054-.109h-1.8c-.56 0-.84 0-1.054.11a1 1 0 0 0-.437.436c-.11.214-.11.494-.11 1.054v1.8c0 .56 0 .84.11 1.054a1 1 0 0 0 .437.438c.213.108.494.108 1.054.108Z'
      stroke='#131A29'
      strokeWidth={2}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

export default DiagramSvg;
