import { FormattedMessage } from 'react-intl';

export const PASSWORD_INPUT_VALIDATION = {
  isNumber: (value) =>
    /^(?=.*[0-9])/.test(value) || (
      <FormattedMessage
        id="form.error.passwordMessage_5"
        defaultMessage="Password should contain at least one digit"
      />
    ),
  isUpper: (value) =>
    /^(?=.*[a-zа-я])/.test(value) || (
      <FormattedMessage
        id="form.error.passwordMessage_2"
        defaultMessage="Password should contain at least one lowercase letter"
      />
    ),
  isLower: (value) =>
    /^(?=.*[A-ZА-Я])/.test(value) || (
      <FormattedMessage
        id="form.error.passwordMessage_4"
        defaultMessage="Password should contain at least one uppercase letter"
      />
    ),
  isSimbol: (value) =>
    /^(?=.*[!@#$%^&*\.,?;:"'№_=\/<>\|{}`~()\[\]\\])/.test(value) || (
      <FormattedMessage
        id="form.error.passwordMessage_1"
        defaultMessage="Password should contain at least one special symbol"
      />
    ),
  minLength: (value) =>
    /^.{8,}$/.test(value) || (
      <FormattedMessage
        id="form.error.passwordMessage_6"
        defaultMessage="Password must contain at least 8 characters"
      />
    ),
};
