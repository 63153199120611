import React, { useState, useEffect, FC, ReactNode } from 'react';
import { useForm } from 'react-hook-form';
import './signup.scss';
import { Link, useNavigate } from 'react-router-dom';
import FormInput from '../../../components/ui/FormInput';
import PasswordInput from '../../../components/ui/PasswordInput';
import Select from '../../../components/ui/Select/Select';
import LangSwitcher from '../../../components/ui/LangSwitcher';
import Button from '../../../components/ui/Button';
import { Keyhole, CheckCircle } from 'phosphor-react';
import { Container, Row, Col } from 'react-bootstrap';
import CheckBox from '../../../components/ui/CheckBox';
import { signUp } from '../../../api/auth';
import { getCountryList } from '../../../api/country';
import PhoneInput from '../../../components/ui/PhoneInput';
import { notify } from '../../../utils/notify';
import Preloader from '../../../components/ui/Preloader/Preloader';
import { defineMessages, FormattedMessage, IntlShape, useIntl } from 'react-intl';
import { PASSWORD_INPUT_VALIDATION } from '../../../constants/validators';
import PrivacyPopup from '../../../components/ui/PrivacyPopup/PrivacyPopup';
import TermsPopup from '../../../components/ui/TermsPopup/TermsPopup';
import PoliciesPopup from '../../../components/ui/PoliciesPopup';
import DesclamierPopup from '../../../components/ui/DesclamierPopup';
import { useWindowWidth } from '@react-hook/window-size';
import queryString, { ParsedQuery } from "query-string";
import { useSelector } from "react-redux";
import { getCompanyName, getImageSignUp, getLang, getProperLogo, selectAuth } from '../../../utils/selectors';
import * as LOGOS from '../../../assets/img/'
import { useGetProhibitedCountries } from "../../../utils/useGetProhibitedCountries";
import ReactTooltip from "react-tooltip";
import { COUNTRY_NOT_ALLOWED } from "../../../constants/errorKeys";
import { useRegionValidation } from "../../../utils/useRegionValidation";

interface ICountry {
  value: number,
  label: string,
  iso_3166_2: string,
}

interface ISendData {
  [key: string]: string | string[],
}
const SignUp: FC = () => {
  const {
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm({
    reValidateMode: 'onChange',
  });

  // console.log(LOGOS[process.env.REACT_APP_DEFAULT_ICON].default)

  const companyName = useSelector(getCompanyName);
  const auth = useSelector(selectAuth);
  const logo = useSelector(getProperLogo)
  const lang = useSelector(getLang)
  const [countryOpt, setCountryOpt] = useState<ICountry[]>([]);
  const [registrationStatus, setRegistrationStatus] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const intl:IntlShape = useIntl();
  const imageSignUp = useSelector(getImageSignUp)
  const navigate = useNavigate();

  const phoneCountry = watch('country_id');
  const width: number = useWindowWidth();
  const values: ParsedQuery<string> = queryString.parse(window.location.search)
  let secret_key: string | string[] = values.key;
  const { list: prohibitedCountries, iso: prohibitedCountriesCodes, loading: prohibitedCountriesLoading }= useGetProhibitedCountries();

  const onSubmit = (data): void => {
    setIsLoading(true);
    const senData: ISendData = {
      first_name: data.first_name,
      last_name: data.last_name,
      email: data.email,
      phone: data.phone || '---',
      password: data.password,
      country_id: data.country_id.value,
      not_citizen_usa: data.not_citizen_usa,
      policy: data.policy,
      adult: data.adult,
      referral_code: secret_key,
      language_code: lang,
    };

    signUp(senData)
      .then((response): void => {
        if (!response.errors) {
          setRegistrationStatus(true);
        }
      })
      .catch((error): void => {
        if (error && error.response.data !== null) {
          const errors = error.response.data?.errors;
          const interceptorException = error.response.data.message === COUNTRY_NOT_ALLOWED;
          const errorMessage = [errors?.country_id?.[0], errors?.phone?.[0]].includes(COUNTRY_NOT_ALLOWED) ?
              intl.formatMessage({
                id: 'auth.signUp.notAllowed',
                defaultMessage: 'Access is blocked due to the region where you are located. Please contact customer support.',
              }) : error.response;

          if (!interceptorException) {
            notify({
              type: 'error',
              message: errorMessage,
              timeOut: 10000,
            });
          }
        }
      })
      .finally((): void => {
        setIsLoading(false);
      })
  };
  // const changingTabTitle = (): void => {
  //   document.title = intl.formatMessage({
  //     id: 'auth.signUp.title',
  //     defaultMessage: 'Sign Up',
  //   });
  // };

  useRegionValidation();

  useEffect((): void => {
    if (auth.isAuthorized) {
      navigate('/finance');
    }

    // changingTabTitle();
    getCountryList().then((res): void => {
      if (res.status === 200) {
        const opt = res.data.data.map((opt):ICountry => ({
          value: opt.id,
          label: opt.name,
          iso_3166_2: opt.iso_3166_2,
        }));
        setCountryOpt(opt);
      }
    });
  }, []);
  
  const getProperImage = () => {
    const staticLogo = LOGOS[process.env.REACT_APP_DEFAULT_ICON];

    if (typeof staticLogo === 'string') {
      return staticLogo;
    }

    return staticLogo.default;
  }

  const renderSuccessfullyRegistration = () => (
    <div className='sign_up'>
      <div className={'page'}>
        <div className={'page__box'}>
          <div className={'page__box-header'}>
            <div className={'logo'}>
              {logo ? (
                <img src={logo} alt="logo" />
              ) : (
                <img src={getProperImage()} alt="" />
              )}
            </div>
            <div className={'page__box-header-icon'}>
              <CheckCircle size={132} />
            </div>
            <div className={'page__box-lang-switcher'}>
              <LangSwitcher />
            </div>
          </div>
          <div className={'page__box-content text-page'}>
            <p className={'title'}>
              <FormattedMessage
                id="letter.title"
                defaultMessage="The letter was successfully sent."
              />
            </p>
            <p>
              <FormattedMessage
                id="letter.dscr_1"
                defaultMessage="Please check and confirm your email."
              />
            </p>
          </div>
        </div>
      </div>
    </div>
  );

  const prepeareLocalizedLabel = () => {
    const privacyButton: JSX.Element = (
      <PrivacyPopup
        trigger={
          <a className={'privacy-btn'}>
            <FormattedMessage
              id="auth.signUp.fieldName.terms.privacy"
              defaultMessage="Privacy Policy"
            />
          </a>
        }
      />
    );

    const policiesButton: JSX.Element = (
      <PoliciesPopup
        trigger={
          <a className={'privacy-btn'}>
            <FormattedMessage
              id="auth.signUp.fieldName.policies"
              defaultMessage="Company Policies"
            />
          </a>
        }
      />
    );

    const desclamierButton: JSX.Element = (
      <DesclamierPopup
        trigger={
          <a className={'privacy-btn'}>
            <FormattedMessage
              id="auth.signUp.fieldName.desclamier"
              defaultMessage="Terms and Conditions and Desclamier"
            />
          </a>
        }
      />
    );

    const termsButton: JSX.Element = (
      <TermsPopup
        trigger={
          <a className={'privacy-btn'}>
            <FormattedMessage
              id="auth.signUp.fieldName.terms.terms"
              defaultMessage="Terms and Conditions of the Client Agreement"
            />
          </a>
        }
      />
    );

    const termsMsg = defineMessages({
      terms: {
        id: 'auth.signUp.fieldName.terms',
        // defaultMessage:
        //   'I have read and accept the {termsButton}, {policiesButton}, {privacyButton}, {desclamierButton}. I also acknowledge and consent to the processing of my personal data in accordance to the DEFAULT COMPANY.',
        defaultMessage:
        `By submitting information I confirm that I agree to the ${process.env.REACT_APP_COMPANY_NAME} Terms and Conditions and Privacy Policy and may be contacted about my application.`,
        description: '',
        
      },
      
    })
    // console.log('termsMsg', termsMsg)
    // .replace('{REACT_APP_COMPANY_NAME}', process.env.REACT_APP_COMPANY_NAME);

    const finalText: ReactNode = intl.formatMessage(termsMsg.terms, {
      termsButton,
      privacyButton,
      desclamierButton,
      policiesButton,
      REACT_APP_COMPANY_NAME: process.env.REACT_APP_COMPANY_NAME
    });
    //@ts-ignore
    // return finalText.replace('{REACT_APP_COMPANY_NAME}', process.env.REACT_APP_COMPANY_NAME);
    return finalText;
  };

  return (
    <>
      {!registrationStatus ? (
        <div className='sign_up'>
          <div className={'page'}>
            <div className={'login auth'}>
              <div className={'logo'}>
                <img src={logo} alt="logo" />
              </div>
              <div className={'login__col auth__descr'}>
                <div className={'auth__descr-img'}>
                  {
                    imageSignUp ?
                      <img src={imageSignUp } alt="" />
                      :
                      <Keyhole size={200} />
                  }
                </div>
                <div className={'auth__descr-text'}>
                  <p>
                    <FormattedMessage
                      id="auth.signUp.descr.title"
                      defaultMessage="Already registered?"
                    />
                  </p>
                  <Link to="/" className={'link-text'}>
                    <FormattedMessage id="auth.signUp.signInBtnText" defaultMessage="Sign in here" />
                  </Link>
                </div>
              </div>
              <div className={'login__col'}>
                <div className={'login__header'}>
                  <div className={'login__title'}>
                    {/*<FormattedMessage id="auth.signUp.title" defaultMessage="Sign Up" />*/}
                  </div>
                  <LangSwitcher />
                </div>
                <div className={'login__content'}>
                  <form className={'form'} onSubmit={handleSubmit(onSubmit)}>
                    {isLoading ? <Preloader /> : null}
                    <Container className={isLoading ? 'p-0 blur' : 'p-0'}>
                      <Row className={'form__line'}>
                        <Col md={6} xs={12}>
                          <FormInput
                            name="first_name"
                            type="text"
                            label={
                              <FormattedMessage
                                id="auth.signUp.fieldName.firstName"
                                defaultMessage="First name"
                              />
                            }
                            control={control}
                            rules={{
                              maxLength: {
                                value: 100,
                                message: <FormattedMessage
                                id="form.error.maxLengthFirstName"
                                defaultMessage="Maximum length is 100 characters"
                              />
                              },
                              required: (
                                <FormattedMessage
                                  id="form.error.requiredFieldnoName"
                                  defaultMessage="Field is required"
                                />
                              ),
                            }}
                            errors={errors.first_name}
                          />
                        </Col>
                        <Col md={6} xs={12}>
                          <FormInput
                            name="last_name"
                            type="text"
                            label={
                              <FormattedMessage
                                id="auth.signUp.fieldName.lastName"
                                defaultMessage="Last name"
                              />
                            }
                            control={control}
                            rules={{
                              maxLength: {
                                value: 250,
                                message: <FormattedMessage
                                id="form.error.maxLengthLastName"
                                defaultMessage="Maximum length is 250 characters"
                              />
                              },
                              required: (
                                <FormattedMessage
                                  id="form.error.requiredFieldnoName"
                                  defaultMessage="Field is required"
                                />
                              ),
                            }}
                            errors={errors.last_name}
                          />
                        </Col>
                      </Row>
                      <Row className={'form__line'}>
                        <Col md={6} xs={12}>
                          <FormInput
                            name="email"
                            type="text"
                            label={
                              <FormattedMessage
                                id="auth.signUp.fieldName.eMail"
                                defaultMessage="E-mail"
                              />
                            }
                            control={control}
                            rules={{
                              maxLength: {
                                value: 256,
                                message: <FormattedMessage
                                id="form.error.maxLengthEmail"
                                defaultMessage="Maximum length is 256 characters"
                              />
                              },
                              required: (
                                <FormattedMessage
                                  id="form.error.requiredField"
                                  defaultMessage="Field is required"
                                  values={{
                                    fieldName: 'E-mail',
                                    //@ts-ignore
                                    code: (fieldName) => <span>{fieldName}</span>,
                                  }}
                                />
                              ),
                              validate: {
                                correctEmail: (value) =>
                                  /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value) || (
                                    <FormattedMessage
                                      id="form.error.email"
                                      defaultMessage="Incorrect E-mail address"
                                    />
                                  ),
                              },
                            }}
                            errors={errors.email}
                          />
                        </Col>
                        <Col md={6} xs={12}>
                          <PasswordInput
                            name="password"
                            type="password"
                            label={
                              <FormattedMessage
                                id="auth.signUp.fieldName.password"
                                defaultMessage="Password"
                              />
                            }
                            control={control}
                            rules={{
                              maxLength: {
                                value: 100,
                                message: <FormattedMessage
                                id="form.error.maxLengthPassword"
                                defaultMessage="Maximum length is 100 characters"
                              />
                              },
                              required: (
                                <FormattedMessage
                                  id="form.error.passwordMessage_7"
                                  defaultMessage="Password field is required"
                                />
                              ),
                              validate: PASSWORD_INPUT_VALIDATION,
                            }}
                            errors={errors.password}
                          />
                        </Col>
                      </Row>
                      <Row className={'form__line'}>
                        <Col md={6} xs={12}>
                          <Select
                            name="country_id"
                            label={
                              <FormattedMessage
                                id="auth.signUp.fieldName.country"
                                defaultMessage="Country"
                              />
                            }
                            control={control}
                            rules={{
                              required: (
                                <FormattedMessage
                                  id="form.error.requiredFieldnoName"
                                  defaultMessage="Field is required"
                                />
                              ),
                            }}
                            errors={errors.country_id}
                            options={countryOpt}
                          />
                        </Col>
                        <Col md={6} xs={12}>
                          {!prohibitedCountriesLoading ?
                              <PhoneInput
                                  name="phone"
                                  type="text"
                                  label={
                                    <FormattedMessage
                                        id="auth.signUp.fieldName.phone"
                                        defaultMessage="Phone"
                                    />
                                  }
                                  control={control}
                                  country={
                                    phoneCountry && phoneCountry.iso_3166_2
                                        ? phoneCountry.iso_3166_2.toLowerCase()
                                        : 'gb'
                                  }
                                  exclude={prohibitedCountriesCodes}
                                  rules={{
                                    required: true,
                                  }}
                              /> : null}
                        </Col>
                      </Row>
                      <Row className={'form__line mb-10'}>
                        <Col>
                          <CheckBox
                            control={control}
                            name="policy"
                            type="checkbox"
                            label={prepeareLocalizedLabel()}
                            id="policy"
                            labelNoClickable={true}
                            rules={{
                              required: true,
                            }}
                            errors={errors.policy}
                          />
                        </Col>
                      </Row>
                      <Row className={'form__line mb-10'}>
                        <Col>
                          <CheckBox
                            control={control}
                            name="adult"
                            type="checkbox"
                            label={
                              <>
                                <FormattedMessage
                                    id="auth.signUp.fieldName.age"
                                    defaultMessage="I certify that I am over 18 and I am not a citizen of the prohibited countries."
                                />
                                <span data-tip data-for='prohibited_countries'>
                                  <svg width="18" height="18">
                                    <use href="#ic_question"></use>
                                  </svg>
                                </span>
                                {/**@ts-ignore**/}
                                <ReactTooltip id='prohibited_countries'>
                                  {prohibitedCountries.join(', ')}
                                </ReactTooltip>
                              </>
                            }
                            // labelNoClickable={true}
                            id="adult"
                            rules={{
                              required: true,
                            }}
                            errors={errors.adult}
                          />
                        </Col>
                      </Row>
                      <Row className={'over-text'}>
                        <Col>
                          <FormattedMessage
                            id="auth.signUp.fieldName.overText"
                            defaultMessage="We care about your privacy. We do not sell or share your information with anyone else."
                          />
                        </Col>
                      </Row>
                      {width <= 500 ? (
                        <Row className={'form__line mb-10'}>
                          <Col>
                            <div className={'auth__descr-text'}>
                              <p>
                                <FormattedMessage
                                  id="auth.signUp.descr.title"
                                  defaultMessage="Already registered?"
                                />
                              </p>
                              <Link to="/" className={'link-text'}>
                                <FormattedMessage
                                  id="auth.signUp.signInBtnText"
                                  defaultMessage="Sign in here"
                                />
                              </Link>
                            </div>
                          </Col>
                        </Row>
                      ) : (
                        ''
                      )}
                      <div className={'button-wrap'}>
                        <Button
                          type="submit"
                          buttonText={
                            <FormattedMessage id="auth.signUp.btn" defaultMessage="Sign up" />
                          }
                          size="big"
                          disabled={isLoading}
                        />
                      </div>
                    </Container>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        renderSuccessfullyRegistration()
      )}
      <div className={'copyright'}>
        © {new Date().getFullYear()} {companyName}, Inc. All rights Reserved
      </div>
    </>
  );
};

export default SignUp;
