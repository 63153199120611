import Page from 'pages/Page/Page';
import { DashboardLayout } from 'pages/UserView/DashboardLayout';
import React, { useEffect, useRef, useState } from 'react';
import { NavigateFunction, useLocation, useNavigate } from 'react-router-dom';
import FormInput from '../../../components/ui/FormInput/FormInput';
import NumberInput from '../../../components/ui/NumberInput/NumberInput.jsx';
import { useForm } from 'react-hook-form';
import { Col, Container, Row } from 'react-bootstrap';
import { FormattedMessage, IntlShape, useIntl } from 'react-intl';
import {
  createTransaction,
  createTransactionKasha,
  fetchPayment,
} from 'api/payments-currency';
import axios from 'axios';
import LoaderButton from '../../../components/ui/LoaderButton/LoaderButton';
import { getMyInfo } from '../../../api/myProfile';
import { useSelector } from 'react-redux';
import { getLang } from '../../../utils/selectors';
import { useWindowWidth } from '@react-hook/window-size';
import { isDevice } from 'constants/mobile';
import Select from '../../../components/ui/Select/Select';
import { getCountryList } from '../../../api/country';
import { PAYMENTS } from '../../../constants/deposit';

interface ICountry {
  value: number,
  label: string,
  iso_3166_2: string,
}

const PaymentForm = () => {
  const navigate: NavigateFunction = useNavigate();
  const { state }: any = useLocation();
  const intl: IntlShape = useIntl();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [countryOpt, setCountryOpt] = useState([]);
  const [amount, setAmount] = useState<number>(state?.amount);
  const lang = useSelector(getLang);
  const width: number = useWindowWidth();
  const device: string = isDevice(width);
  const componentMounted = useRef(true);

  const title: string = intl.formatMessage({
    id: 'payment.form.title',
    defaultMessage: 'Enter your data',
  });

  const isNotParameters: boolean = !(
    state.amount &&
    state.trading_account_id &&
    state.payment_currency &&
    state.gateway
  );
  const {
    handleSubmit,
    control,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    reValidateMode: 'onChange',
  });
  const fetchCountryList = (): void => {
    getCountryList(state.payment_currency).then((res): void => {
      if (componentMounted.current && res.status === 200) {
        const opt = res.data.data.map((opt): ICountry => ({
          value: opt.name,
          label: opt.name,
          iso_3166_2: opt.iso_3166_2,
        }));
        setCountryOpt(opt);
      }
    })
      .catch(error => console.log(error));
  };

  const fetchMyInfo = (): void => {
    getMyInfo()
      .then((res): void => {
        const userData = res.data.data;
        reset({
          amount: state.amount,
          firstname: userData.profile.first_name,
          lastname: userData.profile.last_name,
          city: userData.profile.city,
          address: userData.profile.address,
          email: userData.email,
          phone: userData.phone,
        });
      })
      .catch(error => console.log(error));
  };

  useEffect((): () => void => {
    if (isNotParameters) {
      navigate('/finance');
    }
    if (componentMounted.current) {
      fetchMyInfo();
      fetchCountryList();
    }
    return (): void => {
      componentMounted.current = false;
    };
  }, [state]);

  const onSubmit = async (data): Promise<void> => {
    setIsLoading(true);

    const IP_response = await axios.get('https://geolocation-db.com/json/');
    if (!IP_response.data.IPv4) {
      setIsLoading(false);
      navigate('/payment/fail');
    }

    state.amount = data.amount;

    try {
      const payment = await fetchPayment(state);

      const isFail: boolean = !(
        payment.data.result &&
        payment.data.result === 'success' &&
        payment.data.payment_id &&
        (state.gateway === PAYMENTS.mandato ? payment.data.token : true)
      );

      if (isFail) {
        navigate('/payment/fail');
      }

      const token = payment.data.token;
      const paymentID = payment.data.payment_id;

      switch (state.gateway) {
        case PAYMENTS.kasha:
          const payload_kasha = {
            country: data.country.iso_3166_2,
            currency: state.payment_currency,
            amount: data.amount,
            redirectUrl: 'https://merchant.io/where-to-go',
            language: lang,
            customer: {
              name: `${data.firstname} ${data.lastname}`,
              email: data.email,
              phone: '+' + data.phone,
              userDevice: device,
              userAgent: navigator.userAgent.toLowerCase(),
              ip: IP_response.data.IPv4 !== 'Not found' ? IP_response.data.IPv4 : '109.242.79.100',
              address: {
                street: data.address,
                streetNumber: data.streetNumber,
                country: data.country.iso_3166_2,
                zipCode: data.zip,
                city: data.city,
                state: data.state,
              },
            },
            token: token,
            paymentId: paymentID,
            gateway: state.gateway,
          };

          createTransactionKasha(payload_kasha)
            .then(result => {
              if (result.data?.redirect?.redirect_url) {
                window.open(result?.data?.redirect?.redirect_url, '_blank');
                navigate('/finance');
              }
            })
            .catch(() => navigate('/payment/fail'))
            .finally(() => setIsLoading(false));
          break;

        case PAYMENTS.mandato:
          const payload = {
            token: token,
            payment_id: paymentID,
            gateway: state.gateway,
            firstname: data.firstname,
            lastname: data.lastname,
            address: data.address,
            city: data.city,
            zip: data.zip,
            state: data.state,
            phoneNumber: '+' + data.phone,
            email: data.email,
            ip: IP_response.data.IPv4 !== 'Not found' ? IP_response.data.IPv4 : '109.242.79.100',
            cardNumber: data.cardnumber,
            cardName: data.cardname,
            cvv: data.cvv,
            month: data.cardmonth,
            year: data.cardyear,
          };

          createTransaction(payload)
            .then(result => {
              if (result.data?.redirect?.redirect_url) {
                window.open(result?.data?.redirect?.redirect_url, '_blank');
                navigate('/finance');
              }
            })
            .catch(() => navigate('/payment/fail'))
            .finally(() => setIsLoading(false));
          break;
      }
    } catch (e) {
      console.log(e);
      setIsLoading(false);
      navigate('/payment/fail');
    }
  };

  return (
    <DashboardLayout>
      <Page title={`${title} ${state.gateway}`}>
        <Container>
          <Row>
            <Col md={6} xs={12}>
              <FormInput
                name='amount'
                control={control}
                value={amount}
                onChange={(value): void => {
                  setAmount(value);
                }}
                currency={state?.payment_currency}
                label={
                  <FormattedMessage
                    id='payment.form.amount'
                    defaultMessage='Amount'
                  />
                }
                errors={errors.amount}
                rules={{ required: ' Field is required', maxLength: { value: 16, message: 'This value is too long ' } }}
              />
              <FormInput
                name='firstname'
                control={control}
                label={
                  <FormattedMessage
                    id='payment.form.firstname'
                    defaultMessage='First name'
                  />
                }
                errors={errors.firstname}
                rules={{
                  required: ' Field is required',
                  maxLength: { value: 256, message: 'This value is too long ' },
                }}
              />
              <FormInput
                name='lastname'
                control={control}
                label={
                  <FormattedMessage
                    id='payment.form.lastname'
                    defaultMessage='Last name'
                  />
                }
                errors={errors.lastname}
                rules={{
                  required: ' Field is required',
                  maxLength: { value: 256, message: 'This value is too long ' },
                }}
              />
              {state.gateway === PAYMENTS.kasha ?
                <Select
                  name='country'
                  label={
                    <FormattedMessage
                      id='auth.signUp.fieldName.country'
                      defaultMessage='Country'
                    />
                  }
                  control={control}
                  rules={{
                    required: (
                      <FormattedMessage
                        id='form.error.requiredFieldnoName'
                        defaultMessage='Field is required'
                      />
                    ),
                  }}
                  errors={errors.country_id}
                  options={countryOpt}
                /> : null
              }
              <FormInput
                name='address'
                control={control}
                label={
                  <FormattedMessage
                    id='payment.form.address'
                    defaultMessage='Address'
                  />
                }
                errors={errors.address}
                rules={{
                  required: ' Field is required',
                  maxLength: { value: 256, message: 'This value is too long ' },
                }}
              />
              {state.gateway === PAYMENTS.kasha ?
                <NumberInput
                  name='streetNumber'
                  label={
                    <FormattedMessage
                      id='payment.form.streetNumber'
                      defaultMessage='Street number'
                    />
                  }
                  control={control}
                  errors={errors.streetNumber}
                  rules={{
                    required: ' Field is required',
                    maxLength: { value: 16, message: 'This value is too long ' },
                  }}
                /> : null}
              <FormInput
                name='city'
                control={control}
                label={
                  <FormattedMessage
                    id='payment.form.city'
                    defaultMessage='City'
                  />
                }
                errors={errors.city}
                rules={{
                  required: ' Field is required',
                  maxLength: { value: 256, message: 'This value is too long ' },
                }}
              />
              <FormInput
                name='zip'
                control={control}
                label={
                  <FormattedMessage
                    id='payment.form.zip'
                    defaultMessage='ZIP'
                  />
                }
                errors={errors.zip}
                rules={{ required: ' Field is required', maxLength: { value: 16, message: 'This value is too long ' } }}
              />
              <FormInput
                name='state'
                control={control}
                label={
                  <FormattedMessage
                    id='payment.form.state'
                    defaultMessage='State'
                  />
                }
                errors={errors.state}
                rules={{
                  required: ' Field is required',
                  maxLength: { value: 256, message: 'This value is too long ' },
                }}
              />
              <NumberInput
                name='phone'
                label={
                  <FormattedMessage
                    id='payment.form.phone'
                    defaultMessage='Phone Number'
                  />
                }
                control={control}
                format='+# (###) ###-####'
                mask='_'
                errors={errors.phone}
                rules={{ required: ' Field is required', maxLength: { value: 18, message: 'This value is too long ' } }}
              />
              <FormInput
                name='email'
                control={control}
                label={
                  <FormattedMessage
                    id='payment.form.email'
                    defaultMessage='Email'
                  />
                }
                errors={errors.email}
                rules={{
                  required: ' Field is required',
                  maxLength: { value: 256, message: 'This value is too long ' },
                }}
              />
            </Col>
            {state.gateway === PAYMENTS.mandato ?
              <Col md={6} xs={12}>
                <NumberInput
                  name='cardnumber'
                  control={control}
                  label={
                    <FormattedMessage
                      id='payment.form.cardnumber'
                      defaultMessage='Card number'
                    />
                  }
                  errors={errors.cardnumber}
                  rules={{
                    required: ' Field is required',
                    maxLength: { value: 24, message: 'This value is too long ' },
                  }}
                />
                <FormInput
                  name='cardname'
                  control={control}
                  label={
                    <FormattedMessage
                      id='payment.form.cardname'
                      defaultMessage='Name on card'
                    />
                  }
                  errors={errors.cardname}
                  rules={{
                    required: ' Field is required',
                    maxLength: { value: 256, message: 'This value is too long ' },
                  }}
                />
                <Row>
                  <Col xs={4}>
                    <NumberInput
                      name='cvv'
                      control={control}
                      label={
                        <FormattedMessage
                          id='payment.form.cvv'
                          defaultMessage='CVV'
                        />
                      }
                      errors={errors.cvv}
                      rules={{
                        required: ' Field is required',
                        maxLength: { value: 4, message: 'This value is too long ' },
                      }}
                    />
                  </Col>
                  <Col xs={4}>
                    <NumberInput
                      name='cardmonth'
                      control={control}
                      label={
                        <FormattedMessage
                          id='payment.form.cardmonth'
                          defaultMessage='Month'
                        />
                      }
                      errors={errors.cardmonth}
                      rules={{
                        required: ' Field is required',
                        maxLength: { value: 4, message: 'This value is too long ' },
                      }}
                    />

                  </Col>
                  <Col xs={4}>
                    <NumberInput
                      name='cardyear'
                      control={control}
                      label={
                        <FormattedMessage
                          id='payment.form.cardyear'
                          defaultMessage='Year'
                        />
                      }
                      errors={errors.cardyear}
                      rules={{
                        required: ' Field is required',
                        maxLength: { value: 4, message: 'This value is too long ' },
                      }}
                    />
                  </Col>
                </Row>
                <LoaderButton
                  buttonType='primary'
                  size='big'
                  onClick={handleSubmit(onSubmit)}
                  showSpinner={isLoading}
                  disabled={isLoading}
                  buttonText={
                    <FormattedMessage
                      id='payment.form.submit'
                      defaultMessage='Submit'
                    />
                  }
                />
              </Col> : null}
            {
              state.gateway === PAYMENTS.kasha ?
                <LoaderButton
                  buttonType='primary'
                  size='big'
                  onClick={handleSubmit(onSubmit)}
                  showSpinner={isLoading}
                  disabled={isLoading}
                  buttonText={
                    <FormattedMessage
                      id='payment.form.submit'
                      defaultMessage='Submit'
                    />
                  }
                /> : null
            }
          </Row>
        </Container>
      </Page>
    </DashboardLayout>
  );
};

export default PaymentForm;
