import React, { ReactChild } from 'react';
import './controls-group.scss';

interface IPage {
  children?: any,
  className?: any,
}

const ControlsGroup = ({ children } : IPage) => {
  const rows = Array.isArray(children) ? children : [children];

  return (
    <div className={'control-group'}>
      {rows.map((item, pos) => (
        <div key={pos} className={'control-group-row'}>
          {item}
        </div>
      ))}
    </div>
  );
};

export default ControlsGroup;
