import api from "../api";
import {
    ChatMessageType,
    ChatUserInfo,
    GetMessagesResponse, GetNextMessagesResponse,
    GetThreadsResponse,
    GetThreadsUnreadResponse,
    SendDocumentMessagePayload,
    SendImageMessagePayload,
    SendTextMessagePayload,
    Thread,
    UpdateChatUserInfoPayload
} from "../../types/Chat";
import axios from "axios";
import { store } from "../../store";
import { setChatPending, setChatToken } from "../../store/slice/chat/chatSlice";

export const chatUrl = process.env.REACT_APP_API_CHAT_URL;

export const chatApi = axios.create({
    baseURL: chatUrl,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
    },
});

chatApi.interceptors.request.use(
    (config) => {
        const token: string = localStorage.getItem('chat-token');
        config.headers['Authorization'] = 'Bearer ' + token;
        return config;
    },
    (error) => {
        return Promise.reject(error);
    },
);

chatApi.interceptors.response.use(
    (response) => response,
    async (error) => {
        const { status } = error.response;
        if (status === 401 || status === 403 && error?.config?.url?.includes('/api/broadcasting/auth')) {
            store.dispatch(setChatToken(''));
            store.dispatch(setChatPending(true));
            localStorage.removeItem('chat-token');
        }
        return Promise.reject(error);
    },
);

export const chatGetToken = () => api.get('chat/token');

export const chatLogin = (token: string) => chatApi.post('/api/login/token', { token });

export const updateChatUserInfo = (data: UpdateChatUserInfoPayload) => chatApi.post<ChatUserInfo>('/api/messenger/heartbeat', data);

export const getChatThreads = () => chatApi.get<GetThreadsResponse>('/api/messenger/threads');

export const getChatThread = (threadId: string) => chatApi.get<Thread>(`/api/messenger/threads/${threadId}`);

export const readThread = (threadId: string) => chatApi.get<Thread>(`/api/messenger/threads/${threadId}/mark-read`);

export const getChatMessages = (threadId: string) => chatApi.get<GetMessagesResponse>(`/api/messenger/threads/${threadId}/load`);

export const getChatMessagesPage = (threadId: string, page: string) => chatApi.get<GetNextMessagesResponse>(`/api/messenger/threads/${threadId}/messages/page/${page}`);

export const getThreadsUnreadCount = () => chatApi.get<GetThreadsUnreadResponse>(`/api/messenger/unread-threads-count`);

export const sendTextMessage = (threadId: string, data: SendTextMessagePayload) => chatApi.post<ChatMessageType>(`/api/messenger/threads/${threadId}/messages`, data);

export const sendImageMessage = (threadId: string, data: SendImageMessagePayload) => chatApi.post<ChatMessageType>(`/api/messenger/threads/${threadId}/images`, data);

export const sendDocumentMessage = (threadId: string, data: SendDocumentMessagePayload) => chatApi.post<ChatMessageType>(`/api/messenger/threads/${threadId}/documents`, data);
