import useGlobalDOMEvents from "./useGlobalEvents";
import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateUserInfo } from "../../../store/slice/chat/thunks";
import { awayTimeout } from "../constants";
import { selectChatToken } from "../../../store/slice/chat/chatSlice";
import { checkChatFeature } from "./checkChatFeature";

export const useHeartBeat = () => {
    const dispatch = useDispatch();
    const token = useSelector(selectChatToken);
    const active = useRef(false);
    const timeout = useRef(null);
    const enabled = token && checkChatFeature();

    const inactivate = () => {
        clearTimeout(timeout.current);
        timeout.current = null;

        dispatch(updateUserInfo({ away: true }));
        active.current = false;
    };

    const activate = async () => {
        active.current = true;
        await dispatch(updateUserInfo({ away: false }));
    };

    const startTimer = () => {
        timeout.current = setTimeout(() =>  inactivate(), awayTimeout);
    };

    const resetTimer = () => {
        (!timeout.current && !active.current) ? activate() : clearTimeout(timeout.current);
        startTimer();
    };

    useEffect(() => {
        if (enabled) {
            dispatch(updateUserInfo({ away: false }));
            startTimer()
        }

        return () => {
            clearTimeout(timeout.current)
        }
    }, [token]);

    useGlobalDOMEvents({
        active: enabled,
        listeners: {
            mousemove() {
                resetTimer()
            },
            keypress(e) {
                resetTimer()
            },
            touchmove(e) {
                resetTimer()
            }
        }
    })
}
