import React, { useEffect, useState } from 'react';
import { DashboardLayout } from "../../UserView/DashboardLayout";
import Page from "../../Page/Page";
import { FormattedMessage } from "react-intl";
import ControlsGroup from "../../../components/ui/ControlsGroup";
import TransactionTable from "../../../components/ui/Table/TransactionTable";
import TablePagination from "../../../components/ui/TablePagination/TablePagination";
import { ITableLink, ITableMeta } from "../../../types/table";
import { getTransactionHistory } from '../../../api/transaction';

const Cryptocurrency = () => {
  const [tableData, setTableData] = useState([]);
  const [perPageCount, setPerPageCount] = useState<string>('');
  const [tableDataLoading, setTableDataLoading] = useState<boolean>(false);
  const [tableMeta, setTableMeta] = useState<Partial<ITableMeta>>({});
  const [tableLinks, setTableLinks] = useState<Partial<ITableLink>>({});

  const getTablePageParams = (options): string | null => {
    if (!options) {
      return '';
    }

    const { page, navigate, perPage } = options;
    let params;
    params = `per_page=${perPage || perPageCount}`;

    if (page) {
      params = `${params}&page=${page}`;
    } else if (navigate) {
      let toPage;
      toPage = tableLinks[navigate].split('?').reverse()[0];
      params = `${toPage}&${params}`;
    }

    return params;
  };

  const getTransactionList = (options): void => {
    setTableDataLoading(true);
    getTransactionHistory(getTablePageParams(options))
      .then((res): void => {
        if (!res.errors) {
          setTableData(res.data.data);
          setTableMeta(res.data.meta);
          setTableLinks(res.data.links);
          setPerPageCount(res.data.meta.per_page);
        }
      })
      .catch((error): void => {
        console.log(error)
      })
      .finally(() => setTableDataLoading(false));
  };

  useEffect(() => {
    getTransactionList(null);
  }, [])


  return (
    <DashboardLayout>
      <Page
        title={
          <FormattedMessage
            id="page.finance.transactionHistory.table.title"
            defaultMessage="Transaction history"
          />
        }
      >
        <div>
          <div>
            <ControlsGroup>
              <div>
                <TransactionTable
                  data={tableData}
                  perPage={tableMeta?.per_page}
                  showLoader={tableDataLoading}
                  modifyDataHandler={(callback) => setTableData(tableData.map(callback))}
                />
                {tableData?.length ? (
                  <TablePagination
                    goToFirstPage={(): void => {
                      getTransactionList({ navigate: 'first' });
                    }}
                    goToPrevPage={(): void => {
                      getTransactionList({ navigate: 'prev' });
                    }}
                    goToNextPage={(): void => {
                      getTransactionList({ navigate: 'next' });
                    }}
                    goToLastPage={(): void => {
                      getTransactionList({ navigate: 'last' });
                    }}
                    currentPage={tableMeta?.current_page}
                    pagesLength={tableMeta?.last_page}
                    perPageChange={(value): void => {
                      setPerPageCount(value);
                      getTransactionList({ perPage: value });
                    }}
                    goToSelectedPage={(page): void => {
                      getTransactionList({ page });
                    }}
                    isFirstPageDisable={(): boolean => tableMeta?.current_page === 1}
                    isPrevPageDisable={() => !tableLinks?.prev}
                    isNextPageDisable={() => !tableLinks?.next}
                    isLastPageDisable={(): boolean => tableMeta?.current_page === tableMeta?.last_page}
                  />
                ) : (
                  ''
                )}
              </div>
            </ControlsGroup>
          </div>
        </div>
      </Page>
    </DashboardLayout>
  );
};

export default Cryptocurrency;
