import axios from 'axios';
import { notify } from '../utils/notify';
import { COUNTRY_NOT_ALLOWED } from "../constants/errorKeys";

const api = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json;charset=UTF-8',
  },
  timeout: 30000,
});

api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    const lang =
      localStorage.getItem('lang') !== null
        ? JSON.parse(localStorage.getItem('lang'))[0].value
        : 'en';
    config.headers['Authorization'] = 'Bearer ' + token;
    config.headers['X-Localization'] = lang;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

api.interceptors.response.use(
  (response) => {
    const { status } = response;
    if (status === 200 || status === 201) {
      return response;
    }
  },
  (error) => {
    const { status, statusText } = error.response;
    if (status === 500) {
      notify({
        type: 'error',
        message: `${status} ${statusText}`,
        timeOut: 3000,
      });
    }
    if (status === 403) {
      if (error.response.data?.message === COUNTRY_NOT_ALLOWED) {
        const userLanguage = localStorage.getItem('lang');
        sessionStorage.setItem(COUNTRY_NOT_ALLOWED, "true");
        localStorage.clear();
        userLanguage && localStorage.setItem('lang', userLanguage);

        const isPublicRoute = ['/sign-up', '/'].includes(window.location.pathname);
        if (!isPublicRoute) {
          window.location.replace('/');
        }
      }
    }
    if (status === 401) {
      localStorage.removeItem('token');
    }
    return Promise.reject(error);
  },
);

export default api;
