import { useCallback, useEffect, useRef } from 'react';

const clearPollingTimeout = (handle) => {
    if (handle) {
        cancelAnimationFrame(handle.id);
    }
};

const setPollingTimeout = (callback, timeout = 0) => {
    const handle = {requestId: 0};

    let startTime = Date.now();

    const loop = () => {
        if (Date.now() - startTime >= timeout) {
            callback();
        } else {
            handle.requestId = requestAnimationFrame(loop);
        }
    };

    handle.requestId = requestAnimationFrame(loop);

    return handle;
};

const usePolling = (asyncFn, timeout = 0) => {
    const fnRef = useRef(asyncFn);
    const timerRef = useRef(null);
    const endedRef = useRef(false);

    const endCb = useCallback(() => {
        endedRef.current = true;
        clearPollingTimeout(timerRef.current);
    }, []);

    useEffect(() => {
        async function pollingWithTimeout() {
            await fnRef.current();

            if (!endedRef.current) {
                timerRef.current = setPollingTimeout(pollingWithTimeout, timeout);
            }
        }

        pollingWithTimeout();

        return () => {
            endedRef.current = true;
            clearPollingTimeout(timerRef.current);
        };
    }, [timeout]);

    return endCb;
};

export default usePolling;