import React, { useEffect, useRef } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import Preloader from "../../../../components/ui/Preloader";
import { ChatMessage } from "../ChatMessage/ChatMessage";
import { ChatMessageType } from "../../../../types/Chat";
import { ChatInfiniteScrollStyle } from "./constants";
import { selectChatUserId, selectScrollToTheLast, selectUserStatuses } from "../../../../store/slice/chat/chatSlice";
import { useAppSelector } from "../../../../store";
import './ChatMessagesList.scss';

type ChatMessagesListProps = {
    messages: ChatMessageType[],
    selectedMessage: ChatMessageType,
    handleContextMenu,
    loadMore: () => void,
    hasMore: boolean
};

export const ChatMessagesList = ({ messages, selectedMessage, handleContextMenu, loadMore, hasMore }: ChatMessagesListProps) => {
    const lastMessageRef = useRef(null);
    const userId = useAppSelector(selectChatUserId);
    const userStatuses = useAppSelector(selectUserStatuses);
    const scrollToTheLast = useAppSelector(selectScrollToTheLast);

    useEffect(() => {
        if (scrollToTheLast) {
            lastMessageRef?.current?.scrollIntoView();
        }
    }, [messages, scrollToTheLast]);

    return (
        <div className="chat-messages-overflow" id="messagesOverflow">
            {messages.length ? (
                <InfiniteScroll
                    scrollableTarget='messagesOverflow'
                    dataLength={messages.length}
                    next={loadMore}
                    inverse={true}
                    hasMore={hasMore}
                    style={ChatInfiniteScrollStyle}
                    height='100%'
                    loader={<div className='scroll-loader'><Preloader/></div>}
                >
                    <span ref={lastMessageRef}/>
                    {messages.map(message => (
                        <ChatMessage
                            message={message}
                            userStatus={userStatuses[message.owner_id]}
                            activeViaContextMenu={selectedMessage?.id === message.id}
                            onContextMenu={handleContextMenu}
                            userId={userId}
                            key={message.id}
                        />
                        )
                    )}
                </InfiniteScroll>
            ): null }
        </div>
    )
}