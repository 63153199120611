import { createSlice } from '@reduxjs/toolkit';

export const transactionSlice = createSlice({
  name: 'transaction',
  initialState: {
    needToUpdate: {
      value: false
    },
  },
  reducers: {
    setNeedToUpdate: (state, action) => {
      if (action.payload) {
        state.needToUpdate = action.payload;
      }
    },
  },
});

export const { setNeedToUpdate } = transactionSlice.actions;

export default transactionSlice.reducer;
