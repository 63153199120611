import React from 'react';
import './password-button.scss';

const PasswordButton = (props) => {
  return (
    <div>
      <button {...props} className={'password-button'}>
        <svg
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M3.97498 5.4625C4.52726 5.4625 4.97498 5.91021 4.97498 6.4625V11.4625H9.97498C10.5273 11.4625 10.975 11.9102 10.975 12.4625C10.975 13.0148 10.5273 13.4625 9.97498 13.4625H3.97498C3.42269 13.4625 2.97498 13.0148 2.97498 12.4625V6.4625C2.97498 5.91021 3.42269 5.4625 3.97498 5.4625Z"
            fill="#722ED1"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M13.6616 4.23129C15.9897 3.76748 18.403 4.00454 20.5963 4.91251C22.7895 5.82047 24.6643 7.35854 25.9833 9.33214C27.3023 11.3057 28.0063 13.6262 28.0063 16C28.0063 18.3738 27.3023 20.6943 25.9833 22.6679C24.6643 24.6415 22.7895 26.1795 20.5963 27.0875C18.403 27.9955 15.9897 28.2325 13.6616 27.7687C11.3336 27.3049 9.19542 26.161 7.51758 24.4818C7.12722 24.0911 7.12747 23.458 7.51816 23.0676C7.90884 22.6772 8.54201 22.6775 8.93237 23.0682C10.3306 24.4675 12.1124 25.4207 14.0524 25.8073C15.9925 26.1938 18.0035 25.9962 19.8313 25.2396C21.659 24.4829 23.2213 23.2012 24.3205 21.5566C25.4196 19.9119 26.0063 17.9782 26.0063 16C26.0063 14.0218 25.4196 12.0881 24.3205 10.4434C23.2213 8.79878 21.659 7.51706 19.8313 6.76042C18.0035 6.00379 15.9925 5.80623 14.0524 6.19274C12.1124 6.57926 10.3306 7.53248 8.93237 8.93182L8.93104 8.93315L4.68104 13.1706C4.28994 13.5606 3.65678 13.5597 3.26683 13.1686C2.87688 12.7775 2.87781 12.1443 3.26891 11.7544L7.51758 7.51818C7.51781 7.51796 7.51803 7.51773 7.51826 7.51751C9.19599 5.83867 11.3339 4.69505 13.6616 4.23129Z"
            fill="#722ED1"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M10.975 15.25C10.975 14.6977 11.4227 14.25 11.975 14.25H20.975C21.5273 14.25 21.975 14.6977 21.975 15.25V20.75C21.975 21.3023 21.5273 21.75 20.975 21.75H11.975C11.4227 21.75 10.975 21.3023 10.975 20.75V15.25ZM12.975 16.25V19.75H19.975V16.25H12.975Z"
            fill="#722ED1"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M16.475 12.25C16.0772 12.25 15.6956 12.408 15.4143 12.6893C15.133 12.9706 14.975 13.3522 14.975 13.75V15.25C14.975 15.8023 14.5273 16.25 13.975 16.25C13.4227 16.25 12.975 15.8023 12.975 15.25V13.75C12.975 12.8217 13.3437 11.9315 14.0001 11.2751C14.6565 10.6187 15.5467 10.25 16.475 10.25C17.4032 10.25 18.2935 10.6187 18.9498 11.2751C19.6062 11.9315 19.975 12.8217 19.975 13.75V15.25C19.975 15.8023 19.5273 16.25 18.975 16.25C18.4227 16.25 17.975 15.8023 17.975 15.25V13.75C17.975 13.3522 17.8169 12.9706 17.5356 12.6893C17.2543 12.408 16.8728 12.25 16.475 12.25Z"
            fill="#722ED1"
          />
        </svg>
      </button>
    </div>
  );
};

export default PasswordButton;
