import { useEffect, useState } from "react";

const useContextMenu = <E>(menuRef) => {
    const [selectedEntity, setSelectedEntity] = useState<E>(null);
    const [clicked, setClicked] = useState(false);
    const [points, setPoints] = useState({x: 0, y: 0, clientX: 0, clientY: 0});

    const handleSetPoints = ({clientX, clientY}) => {
        const menuWidth = menuRef.current.offsetWidth;
        const menuHeight = menuRef.current.offsetHeight;

        setPoints({
            x: clientX + menuWidth > window.innerWidth ? clientX - menuWidth : clientX,
            y: clientY + menuHeight > window.innerHeight ? clientY - menuHeight : clientY,
            clientX,
            clientY
        });
    };

    useEffect(() => {
        if (menuRef.current) {
            handleSetPoints({ clientX: points.clientX, clientY: points.clientY })
        }

    }, [menuRef.current?.offsetHeight, points.clientY]);

    useEffect(() => {
        const resetContextStates = () => {
            setClicked(false);
            setSelectedEntity(null);
        }

        document.addEventListener("click", resetContextStates);

        return () => {
            document.removeEventListener("click", resetContextStates);
        };
    }, []);

    return {
        clicked,
        setClicked,
        selectedEntity,
        setSelectedEntity,
        points,
        setPoints: handleSetPoints,
    };
};
export default useContextMenu;