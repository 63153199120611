import React from 'react';
import './authUserInfo.scss';
import { User } from 'phosphor-react';


const AuthUserInfo = (props) => {
  const { userName, userAvatar, authStatus } = props;
  return (
    <div className={'user'}>
      <div className={'user__avatar online'}>
        <User size={24} />
      </div>
      <div className={'user__name'}>{userName}</div>
    </div>
  );
};

export default AuthUserInfo;
