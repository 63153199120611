import React from 'react';
import './preloader.scss';

const Preloader = () => {
  return (
    <div className={'loader-wrap'}>
      <div className={'loader'}></div>
    </div>
  );
};

export default Preloader;
