import API from '../api' 

export const signIn = (data) => (
  API.post('api/v1/login', data)
)

export const signUp = (data) => (
  API.post('api/v1/register', data)
)

export const verificationEmail = (verificationData) => (
  API.post('/api/v1/verify/code', verificationData) 
)
export const resetPassword = (data) => (
  API.post('/api/v1/password/reset', data) 
)

export const newPassword = (data) => (
  API.post('/api/v1/new/password/email', data) 
)

export const logOut = () => (
  API.post('/api/v1/logout') 
)