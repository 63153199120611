import * as React from "react"

const CreditCardSvg = (props) => (
  <svg
    width={25}
    height={25}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3.706 9.21h18m-14 7h2m-2.8 3h11.6c1.12 0 1.68 0 2.108-.219a2 2 0 0 0 .874-.873c.218-.428.218-.988.218-2.108v-7.6c0-1.12 0-1.68-.218-2.109a2 2 0 0 0-.874-.874c-.428-.218-.988-.218-2.108-.218h-11.6c-1.12 0-1.68 0-2.108.218a2 2 0 0 0-.874.874c-.218.428-.218.988-.218 2.108v7.6c0 1.12 0 1.68.218 2.108a2 2 0 0 0 .874.874c.427.218.988.218 2.108.218Z"
      stroke="#131A29"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default CreditCardSvg
