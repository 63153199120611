import React from 'react';
import './loader-button.scss';

const LoaderButton = (props) => {
  const {
    buttonText,
    size,
    buttonType,
    icon,
    classes,
    buttonStyle,
    onClick,
    showSpinner,
    disabled,
    ...other
  } = props;

  return (
    <div className='loader__button'>
      <button
        className={`btn ${buttonType} ${size} ${buttonStyle}`}
        onClick={onClick}
        disabled={disabled}
        {...other}
      >
        <span className={`btn__text ${showSpinner ? 'hidden' : ''}`}>{buttonText}</span>
        {showSpinner ? (
          <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        ) : (
          ''
        )}
      </button>
    </div>
  );
};

export default LoaderButton;
