import { createSlice } from "@reduxjs/toolkit";

interface IPayments {
  ENABLE_PAYMENT: number,
  ENABLE_CREDIT_CARD: number,
  ENABLE_CREDIT_CARD_WITHDRAW: number,
  ENABLE_WEB_TRADER: number | null,
  PAYMENT_CURRENCY: string[],
  PAYMENT_CURRENCY_KASHA: string[],
  PAYMENT_GATEWAYS: { value: string, label: string }[];
  TRANSLATE: Record<string, string>;
}

const initialState: IPayments = {
  ENABLE_PAYMENT: 0,
  ENABLE_CREDIT_CARD: 0,
  ENABLE_CREDIT_CARD_WITHDRAW: 0,
  ENABLE_WEB_TRADER: null,
  PAYMENT_CURRENCY: [],
  PAYMENT_CURRENCY_KASHA: [],
  PAYMENT_GATEWAYS: [],
  TRANSLATE: {},
}

const paymentsCurrency = createSlice({
  name: 'payments',
  initialState,
  reducers: {
    setPaymentsParameters(state, action) {
      state.ENABLE_PAYMENT = action.payload.ENABLE_PAYMENT
      state.ENABLE_CREDIT_CARD = action.payload.ENABLE_CREDIT_CARD
      state.ENABLE_CREDIT_CARD_WITHDRAW = action.payload.ENABLE_CREDIT_CARD_WITHDRAW
      state.PAYMENT_CURRENCY = action.payload.PAYMENT_CURRENCY
      state.PAYMENT_GATEWAYS = action.payload.PAYMENT_GATEWAYS
      state.ENABLE_WEB_TRADER = action.payload.ENABLE_WEB_TRADER
      state.TRANSLATE = action.payload.TRANSLATE
    },
    setPaymentsKashaCurrency(state, action) {
      state.PAYMENT_CURRENCY_KASHA = action.payload.PAYMENT_CURRENCY
    },
    // setPaymentsMandatoCurrency(state, action) {
    //   state.PAYMENT_CURRENCY = action.payload.PAYMENT_CURRENCY
    // }
  }
})

export const { setPaymentsParameters,
  setPaymentsKashaCurrency,
} = paymentsCurrency.actions;
export default paymentsCurrency.reducer;
