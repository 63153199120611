import { configureStore } from '@reduxjs/toolkit';
import authReducer from './slice/authSlice';
import langListReducer from './slice/langSlice';
import headerInfoReducer from './slice/headerInfoSlice';
import transactionReducer from './slice/transactionSlice';
import nameCompanyReducer from "./slice/settingsCompanySlice";
import paymentsCurrency from "./slice/paymentsCurrency";
import chatReducer from "./slice/chat/chatSlice";
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import ChatListenerMiddleware from "./slice/chat/effects";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    langList: langListReducer,
    headerInfo: headerInfoReducer,
    transactions: transactionReducer,
    companyName: nameCompanyReducer,
    payments: paymentsCurrency,
    chat: chatReducer
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().prepend(ChatListenerMiddleware.middleware)
});


export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
