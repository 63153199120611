import { createListenerMiddleware } from "@reduxjs/toolkit";
import {
    addNewMessage,
    chatInit,
    readThread,
    removeMessage,
    selectChatOpened,
    selectChatPending,
    selectChatToken,
    selectChatUser,
    selectChatUserId,
    selectThread,
    selectThreads,
    setChatToken,
} from "./chatSlice";
import { playNotificationSound } from "../../../pages/Chat/helpers/playNotificationSound";
import { chatAuth, getMessages, getThreads, incomingThread } from "./thunks";
import { createChatConnection } from "../../../api/chat/socket";
import { checkChatFeature } from "../../../pages/Chat/helpers/checkChatFeature";
import { logout } from "../authSlice";

const ChatListenerMiddleware = createListenerMiddleware();

ChatListenerMiddleware.startListening({
    actionCreator: chatInit,
    effect: async (action, { dispatch, getState }) => {
        if (checkChatFeature()) {
            const storedToken = localStorage.getItem('chat-token');
            const token = selectChatToken(getState());

            if (storedToken) {
                // TODO: CHAT remove this condition when DashboardLayout is refactored with Outlet
                storedToken !== token && dispatch(setChatToken(storedToken));
            } else {
                dispatch(chatAuth());
            }
        }
    },
});

ChatListenerMiddleware.startListening({
    actionCreator: setChatToken,
    effect: async (action, { getState, dispatch }) => {
        const state = getState();
        const token = action.payload;
        const userId = selectChatUserId(state);

        if (token) {
            createChatConnection(token, dispatch);
            userId && initChatSubscriptions(dispatch, `messenger.user.${userId}`);
        } else {
            userId && removeChatSubscriptions(`messenger.user.${userId}`);

            if (!selectChatPending(state)) {
                dispatch(chatAuth());
            }
        }
    },
});

ChatListenerMiddleware.startListening({
    predicate: (action, currentState, previousState) => {
        if (action.type === 'chat/user/fulfilled') {
            return !selectChatUser(previousState);
        }

        return false
    },
    effect: async (action, { getState, dispatch }) => {
        const state = getState();
        const userId = selectChatUserId(state);

        initChatThreads(state, dispatch);
        userId && initChatSubscriptions(dispatch, `messenger.user.${userId}`);
    }
});

ChatListenerMiddleware.startListening({
    actionCreator: logout.pending,
    effect: async (action, listenerApi) => {
        if (checkChatFeature()) {
            const userId = selectChatUserId(listenerApi.getState());
            userId && removeChatSubscriptions(`messenger.user.${userId}`);
        }
    }
});

ChatListenerMiddleware.startListening({
    actionCreator: addNewMessage,
    effect: async (action, listenerApi) => {
        const state = listenerApi.getState();
        const userId = selectChatUserId(state);
        const threads = selectThreads(state);

        //TODO: CHAT - remove the condition when fixed undefined Thread
        const messageThreadIndex = threads.findIndex(thread => thread.id === action.payload.thread_id);
        if (messageThreadIndex === -1) {
            listenerApi.dispatch(incomingThread(action.payload.thread_id));
            return
        }

        if (action.payload.owner_id !== userId) {
            playNotificationSound();
        }
    },
});

ChatListenerMiddleware.startListening({
    predicate: (action, currentState, previousState) => {
        if (action.type === 'chat/threads/fulfilled') {
            return selectChatOpened(currentState) && selectThread(previousState)?.id !== selectThread(currentState)?.id;
        }

        return false
    },
    effect: async (action, listenerApi) => {
        fetchMessages(listenerApi);
    },
});

ChatListenerMiddleware.startListening({
    actionCreator: incomingThread.fulfilled,
    effect: async (_, __) => {
        playNotificationSound();
    },
});

const fetchMessages = (api) => {
    const state = api.getState();
    const supportId = selectThread(state)?.id;

    if (supportId) {
        api.dispatch(getMessages(supportId));
    }
};

const initChatSubscriptions = (dispatch, privateChannel) => {
    if (privateChannel) {
        window.ChatEcho.private(privateChannel)
            .listen('.new.message', (message) => { dispatch(addNewMessage(message)) })
            .listen('.new.thread', (response) => { dispatch(incomingThread(response.thread.id)) })
            .listen('.message.archived', (response) => { dispatch(removeMessage(response)) })
            .listen('.thread.read', (response) => { dispatch(readThread(response)) })
    }
};

const removeChatSubscriptions = (privateChannel) => {
    privateChannel && window.ChatEcho.leave(privateChannel);
};

const initChatThreads = (state, dispatch) => {
    if (!state.chat.opened && !state.chat.threads.length) {
        // console.log('PING');
        dispatch(getThreads());
    }
};

export default ChatListenerMiddleware;
