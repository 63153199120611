import * as React from "react"

const UserCheck = (props) => (
  <svg
    width={25}
    height={25}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m15.706 18.21 2 2 4-4m-10 4h-3.8c-1.12 0-1.68 0-2.108-.219a2 2 0 0 1-.874-.873c-.218-.428-.218-.988-.218-2.108v-9.6c0-1.12 0-1.68.218-2.109a2 2 0 0 1 .874-.874c.427-.218.988-.218 2.108-.218h9.6c1.12 0 1.68 0 2.108.218a2 2 0 0 1 .874.874c.218.428.218.988.218 2.108v4.8m-13 8a5.002 5.002 0 0 1 5-5m2-5a2 2 0 1 1-4 0 2 2 0 0 1 4 0Z"
      stroke="#131A29"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default UserCheck
