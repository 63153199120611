import React, { FC, useEffect, useState } from 'react';
import { DashboardLayout } from "../../../UserView/DashboardLayout";
import { Link, useParams } from "react-router-dom";
import { getPaymentById } from "../../../../api/payments-currency";
import './payments.scss';
import { IPaymentInfo } from "../../../../types/payments";
import Page from "../../../Page/Page";

const PaymentsSuccess: FC = () => {
  const [paymentInfo, setPaymentInfo] = useState<Partial<IPaymentInfo> | undefined>(undefined)
  const params = useParams()

  const getPaymentByUser = (): void => {
    if (params.id) {
      getPaymentById(params.id)
        .then(res => {
          if (res.status === 200) {
            console.log(res)
            setPaymentInfo(res.data.data)
          }
        })
        .catch(err => console.log(err))
    }
  }

  useEffect(() => {
    getPaymentByUser();
  }, [])


  return (
    <DashboardLayout>
      <Page
        // title={<FormattedMessage id="menu.metaTrader" defaultMessage="Wire transfers" />}
        title={'Payment Success'}
      >
        <div className={'payments'}>
            <div className={'payments__header'}>
              Operation completed successfully!
            </div>
            {
              paymentInfo !== undefined ?
              <div className={'payments__body'}>
                <span>{paymentInfo?.amount} {paymentInfo?.currency}</span> credited to your trading account <span>{paymentInfo?.trading_account?.[0]?.name}</span> ({paymentInfo?.trading_account?.[0]?.trading_account})
              </div> : null
            }
            <Link to="/finance" className={'text-link'}>Go to section Finance</Link>
        </div>
      </Page>
    </DashboardLayout>
  );
};

export default PaymentsSuccess;
