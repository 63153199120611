import React from 'react';
import './accounts-table.scss';
import Button from '../../Button/Button';
import { makeTableData } from '../../../../helpers/table.helper';
import Table from '../Table';
import { TABLE_ACCOUNTS_COLUMNS_NAMES, TABLE_ACCOUNTS_COLUMNS_NAMES_WITH_WEBTRADER } from '../../../../constants/table';
import { POPUP_TYPE } from '../../../../constants/popup';
import FinancePopup from '../../FinancePopup';
import PasswordButton from '../../PasswordButton/PasswordButton';
import PasswordPopup from '../../PasswordPopup/PasswordPopup';
import { FormattedMessage } from 'react-intl';
import { TRADING_ACCOUNT_STATUS } from '../../../../constants/trading-account-status';
import { useSelector } from 'react-redux';
import { getPaymentsEnableWebTrader } from '../../../../utils/selectors';

const AccountsTable = ({ data, perPage, showLoader, modifyDataHandler }) => {
  const ENABLE_WT = useSelector(getPaymentsEnableWebTrader)
  const CURRENT_TABLE = ENABLE_WT ? TABLE_ACCOUNTS_COLUMNS_NAMES_WITH_WEBTRADER : TABLE_ACCOUNTS_COLUMNS_NAMES;

  const columns = React.useMemo(() => CURRENT_TABLE, [ENABLE_WT]);
  const tableData = React.useMemo(() => makeTableData(data, data.length), [data]);

  const prepearedTableData = tableData.map((item, pos) => {
    const { trading_account, name, balance, demo, type, credit, amount_type, status, server, leverage } = item;

    return {
      tradingAcc: (
        <span className={'transaction__id'}>
          <span>{name || '[NO NAME]'}</span>
          <span className={status}>{status}</span>
        </span>
      ),
      login: (
        <span className={'trading_account_accounts_table'}>
          {demo === 1 && <span style={{ color: 'blue', fontWeight: 'bold' }}>DEMO </span>}
          {trading_account}
          {/*<span>{type}</span>*/}
        </span>
      ),
      balance: balance + ' ' + amount_type,
      demo: (
        <div>
          <span>{demo === 1 ? 'Yes' : 'No'}</span>
        </div>
      ),
      // server: server,
      liverage: leverage,
      credit:`${credit ? credit : '0.00'} ${amount_type}`,
      controls: status === TRADING_ACCOUNT_STATUS.ACTIVE && (
        <div className={'table-buttons'}>
          <PasswordPopup
            trigger={<PasswordButton />}
            tradingAccount={item}
            onSuccess={() => console.log('a')}
          />
          <FinancePopup
            type={POPUP_TYPE.DEPOSIT}
            defaultToAccount={item}
            accountsData={data}
            onSuccess={modifyDataHandler}
            trigger={
              <Button
                buttonText={
                  <FormattedMessage id="page.finance.deposit.btn" defaultMessage="Deposit" />
                }
                buttonType="primary"
                size="big"
              />
            }
          />
        </div>
      ),
    };
  });

  return (
    <Table
      columns={columns}
      data={prepearedTableData}
      perPage={perPage}
      showLoader={showLoader}
      className={'account-table'}
      getCellProps={(cellInfo) => {
        const { row, value, column } = cellInfo;
        return {
          style: {
            backgroundColor: `${row.isSelected ? '#F9F1FF' : 'var(--body-background-color)'}`,
          },
        };
      }}
    />
  );
};

export default AccountsTable;
