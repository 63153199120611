import React from "react";
import Preloader from "../../../../components/ui/Preloader";
import { ChatResponseImage } from "../../../../types/Chat";
import { openInNewTab } from "../../helpers/openInNewTab";
import { chatUrl } from "../../../../api/chat";

type ImageBodyProps = {
    image?: ChatResponseImage
};

export const ImageBody = ({ image }: ImageBodyProps) => {
    if (!image) {
        return <div className="chat-message-image pending"><Preloader/></div>
    }

    return (
        <div className="chat-message-image" onClick={() => openInNewTab(chatUrl + image.lg)}>
            <img src={chatUrl + image.lg} alt=''/>
        </div>
    )
};
