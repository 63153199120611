import React from 'react';

import './table-pagination.scss';
import { FormattedMessage, useIntl } from 'react-intl';

const TablePagination = (props) => {
  const intl = useIntl();
  const showPage = intl.formatMessage({
    id: 'table.pagination.showPage',
    defaultMessage: 'Show',
  });
  const page = intl.formatMessage({
    id: 'table.pagination.page',
    defaultMessage: 'Page',
  });
  const tof = intl.formatMessage({
    id: 'table.pagination.tof',
    defaultMessage: 'of',
  });
  const goPage = intl.formatMessage({
    id: 'table.pagination.goPage',
    defaultMessage: 'Go to Page',
  });
  const {
    perPage,
    perPageChange,
    goToSelectedPage,
    goToFirstPage,
    goToPrevPage,
    goToNextPage,
    goToLastPage,
    currentPage,
    pagesLength,
    isFirstPageDisable,
    isPrevPageDisable,
    isNextPageDisable,
    isLastPageDisable,
  } = props;

  return (
    <div className={'pagination'}>
      <button
        onClick={goToFirstPage}
        disabled={isFirstPageDisable()}
        className={'goToFirstPage'}
      >
        {'<<'}
      </button>{' '}
      <button onClick={goToPrevPage} disabled={isPrevPageDisable()} className={'goToPrevPage'}>
        {'<'}
      </button>{' '}
      <button onClick={goToNextPage} disabled={isNextPageDisable()} className={'goToNextPage'}>
        {'>'}
      </button>{' '}
      <button onClick={goToLastPage} disabled={isLastPageDisable()} className={'goToLastPage'}>
        {'>>'}
      </button>{' '}
      <span className={'pageText'}>
        {page}{' '}
        <strong>
          {currentPage} {tof} {pagesLength}
        </strong>{' '}
      </span>
      <span className={'goToSelectedPage'}>
        &nbsp; {goPage}{' '}
        <input
          className={'goToSelectedPage-input'}
          type="number"
          max={pagesLength}
          defaultValue={currentPage}
          onChange={(e) => {
            const page = e.target.value ? Number(e.target.value) : 0;
            if (page > pagesLength) {
              e.target.value = pagesLength;
              return;
            }
            goToSelectedPage(page);
          }}
          style={{ width: '100px' }}
        />
      </span>{' '}
      <select
        className={'perPage'}
        value={perPage}
        onChange={(e) => {
          perPageChange(Number(e.target.value));
        }}
      >
        {[10, 20, 30, 40, 50].map((perPage) => (
          <option key={perPage} value={perPage}>
            {perPage}
          </option>
        ))}
      </select>
    </div>
  );
};

export default TablePagination;
