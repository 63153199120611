import React, { FC, useEffect, useState } from 'react';
import { DashboardLayout } from "../../../UserView/DashboardLayout";
import { Link, Params, useParams } from "react-router-dom";
import { getPaymentById } from "../../../../api/payments-currency";
import './payments.scss';
import { IPaymentInfo } from "../../../../types/payments";
import Page from "../../../Page/Page";

const PaymentsError: FC = () => {
  const [paymentInfo, setPaymentInfo] = useState<Partial<IPaymentInfo> | undefined>(undefined)
  const params: Readonly<Params<string>> = useParams()
  const getPaymentByUser = (): void => {
    if (params.id) {
      getPaymentById(params.id)
        .then(res => {
          if (res.status === 200) {
            setPaymentInfo(res.data.data)
          }
        })
        .catch(err => console.log(err))
    }
  }

  useEffect(() => {
    getPaymentByUser();
  }, [])


  return (
    <DashboardLayout>
      <Page
        // title={<FormattedMessage id="menu.metaTrader" defaultMessage="Wire transfers" />}
        title={'Payment Error'}
      >
        <div className={'payments'}>
          <div className={'payments__header'}>
            Operation failed!
          </div>
          <div className={'payments__body'}>
            {
              paymentInfo !== undefined ?
              <>
                <p>
                  <span>{paymentInfo?.amount} {paymentInfo?.currency}</span> status is <span
                  className={paymentInfo?.status}>{paymentInfo?.status}</span>
                </p>
                <br />
              </> : null
            }
            <p>
              Go to the Finance section and replenish the balance again.
            </p>
          </div>
          <Link to="/finance" className={'text-link'}>Go to the Finance</Link>
        </div>
      </Page>
    </DashboardLayout>
  );
};

export default PaymentsError;
