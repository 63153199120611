import React, { useEffect, useState } from 'react';
import { languages } from '../languages';
import { IntlProvider } from 'react-intl';
import { useDispatch } from "react-redux";
import { setCurrentLang } from "../store/slice/langSlice";

export const Context = React.createContext();

const local =
  localStorage.getItem('lang') !== null ? JSON.parse(localStorage.getItem('lang'))[0].value : 'en';

let lang = languages[local || 'en'];

const LangWrapper = (props) => {
  const [locale, setLocale] = useState(local);
  const [messages, setMessages] = useState(lang);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setCurrentLang(locale))
  }, [dispatch, locale])
  const selectLang = (value) => {

    const newLocale = value.value;
    setLocale(newLocale);

    let saveLangLocalStorage = [
      {
        value: value.value,
        icon: value.icon,
        text: value.text,
        label: value.label,
      },
    ];

    localStorage.setItem('lang', JSON.stringify(saveLangLocalStorage));

    setMessages(languages[newLocale || 'en']);
  };

  return (
    <Context.Provider value={{ locale, selectLang }}>
      <IntlProvider messages={messages} locale={locale}>
        {props.children}
      </IntlProvider>
    </Context.Provider>
  );
};

export default LangWrapper;
