import { FormattedMessage } from 'react-intl';
export const TABLE_HEADER_COLUMNS_NAMES = [
  {
    Header: <FormattedMessage id="table.th.transaction" defaultMessage="Transaction" />,
    accessor: 'transaction',
  },
  {
    Header: <FormattedMessage id="table.th.type" defaultMessage="Type" />,
    accessor: 'type',
  },
  {
    Header: <FormattedMessage id="table.th.amount" defaultMessage="Amount" />,
    accessor: 'amount',
  },
  {
    Header: <FormattedMessage id="page.finance.widthdraw.popup.paymentMethod" defaultMessage="Payment Method" />,
    accessor: 'paymentMethod',
  },
  {
    Header: <FormattedMessage id="table.th.tradingAcc" defaultMessage="Trading Account" />,
    accessor: 'tradingAcc',
  },
  {
    Header: <FormattedMessage id="table.th.dateTime" defaultMessage="DATE & TIME" />,
    accessor: 'dateTime',
  },
  {
    Header: <FormattedMessage id="table.th.status" defaultMessage="Status" />,
    accessor: 'status',
  },
];

export const TABLE_HEADER_COLUMNS_NAMES_WITH_PAYMENTS = [
  {
    Header: <FormattedMessage id="table.th.transaction" defaultMessage="Transaction" />,
    accessor: 'transaction',
  },
  {
    Header: <FormattedMessage id="table.th.type" defaultMessage="Type" />,
    accessor: 'type',
  },
  {
    Header: <FormattedMessage id="table.th.amount" defaultMessage="Amount" />,
    accessor: 'amount',
  },
  {
    Header: <FormattedMessage id="page.finance.widthdraw.popup.paymentMethod" defaultMessage="Payment Method" />,
    accessor: 'paymentMethod',
  },
  {
    Header: <FormattedMessage id="table.th.gateway" defaultMessage="Gateway" />,
    accessor: 'gateway',
  },
  {
    Header: <FormattedMessage id="table.th.tradingAcc" defaultMessage="Trading Account" />,
    accessor: 'tradingAcc',
  },
  {
    Header: <FormattedMessage id="table.th.dateTime" defaultMessage="DATE & TIME" />,
    accessor: 'dateTime',
  },
  {
    Header: <FormattedMessage id="table.th.status" defaultMessage="Status" />,
    accessor: 'status',
  },
];

export const TABLE_HEADER_COLUMNS_NAMES_WIRE_TRANSFERS = [
  {
    Header: <FormattedMessage id="table.th.transaction" defaultMessage="Transaction" />,
    accessor: 'transaction',
  },
  {
    Header: <FormattedMessage id="table.th.type" defaultMessage="Type" />,
    accessor: 'type',
  },
  {
    Header: <FormattedMessage id="table.th.amount" defaultMessage="Amount" />,
    accessor: 'amount',
  },
  {
    Header: <FormattedMessage id="table.th.tradingAcc" defaultMessage="Trading Account" />,
    accessor: 'tradingAcc',
  },
  {
    Header: <FormattedMessage id="page.finance.widthdraw.popup.paymentMethod" defaultMessage="Payment Method" />,
    accessor: 'paymentMethod',
  },
  {
    Header: <FormattedMessage id="table.th.gateway" defaultMessage="Gateway" />,
    accessor: 'gateway',
  },
  {
    Header: <FormattedMessage id="table.th.dateTime" defaultMessage="DATE & TIME" />,
    accessor: 'dateTime',
  },
  {
    Header: <FormattedMessage id="table.th.status" defaultMessage="Status" />,
    accessor: 'status',
  },
];

export const TABLE_ACCOUNTS_COLUMNS_NAMES = [
  {
    Header: <FormattedMessage id="table.th.tradingAcc" defaultMessage="Trading account" />,
    accessor: 'tradingAcc',
  },
  {
    Header: <FormattedMessage id="table.th.login" defaultMessage="Login" />,
    accessor: 'login',
  },
  {
    Header: <FormattedMessage id="table.th.balance" defaultMessage="Balance" />,
    accessor: 'balance',
  },
  {
    Header: <FormattedMessage id="table.th.credit" defaultMessage="Credit" />,
    accessor: 'credit',
  },
  {
    Header: <FormattedMessage id="table.th.liverage" defaultMessage="Leverage" />,
    accessor: 'liverage',
  },
  // {
  //   Header: <FormattedMessage id="table.th.server" defaultMessage="Server" />,
  //   accessor: 'server',
  // },
  {
    Header: '',
    accessor: 'controls',
  },
];

export const TABLE_ACCOUNTS_COLUMNS_NAMES_WITH_WEBTRADER = [
  {
    Header: <FormattedMessage id="table.th.tradingAcc" defaultMessage="Trading account" />,
    accessor: 'tradingAcc',
  },
  {
    Header: <FormattedMessage id="table.th.login" defaultMessage="Login" />,
    accessor: 'login',
  },
  {
    Header: <FormattedMessage id="table.th.balance" defaultMessage="Balance" />,
    accessor: 'balance',
  },
  {
    Header: <FormattedMessage id="table.th.demoAccount" defaultMessage="Demo account" />,
    accessor: 'demo',
  },
  {
    Header: <FormattedMessage id="table.th.credit" defaultMessage="Credit" />,
    accessor: 'credit',
  },
  {
    Header: <FormattedMessage id="table.th.liverage" defaultMessage="Leverage" />,
    accessor: 'liverage',
  },
  // {
  //   Header: <FormattedMessage id="table.th.server" defaultMessage="Server" />,
  //   accessor: 'server',
  // },
  {
    Header: '',
    accessor: 'controls',
  },
];

export const TABLE_WIRE_TRANSFERS_COLUMNS_NAMES = [
  {
    Header: <FormattedMessage id="table.th.accName" defaultMessage="Account Name" />,
    accessor: 'AccName',
  },
  {
    Header: <FormattedMessage id="table.th.tradingAcc" defaultMessage="Trading account" />,
    accessor: 'tradingAcc',
  },
  {
    Header: <FormattedMessage id="table.th.amount" defaultMessage="Amount" />,
    accessor: 'amount',
  },
  {
    Header: <FormattedMessage id="table.th.dateTime" defaultMessage="DATE & TIME" />,
    accessor: 'dateTime',
  },
  {
    Header: <FormattedMessage id="table.th.status" defaultMessage="Status" />,
    accessor: 'status',
  },
];

export const TABLE_TRADING_COLUMNS_NAMES = [
  {
    Header: <FormattedMessage id="table.th.trade" defaultMessage="Trade" />,
    accessor: 'trade',
  },
  {
    Header: <FormattedMessage id="table.th.type" defaultMessage="Type" />,
    accessor: 'type',
  },
  {
    Header: <FormattedMessage id="table.th.symbol" defaultMessage="Symbol" />,
    accessor: 'symbol',
  },
  {
    Header: <FormattedMessage id="table.trading_history.th.openPrice" defaultMessage="Transaction price" />,
    accessor: 'open_price',
  },
  {
    Header: <FormattedMessage id="table.th.volume" defaultMessage="volume" />,
    accessor: 'volume',
  },
  {
    Header: <FormattedMessage id="table.trading_history.th.openTime" defaultMessage="Transaction time" />,
    accessor: 'open_time',
  },
  // {
  //   Header: <FormattedMessage id="table.th.closeTime" defaultMessage="Close Time" />,
  //   accessor: 'close_time',
  // },
  // {
  //   Header: <FormattedMessage id="table.th.closePrice" defaultMessage="Close Price" />,
  //   accessor: 'close_price',
  // },
  // {
  //   Header: <FormattedMessage id="table.th.swap" defaultMessage="Swap" />,
  //   accessor: 'swap',
  // },
  // {
  //   Header: <FormattedMessage id="table.th.profit" defaultMessage="Profit" />,
  //   accessor: 'profit',
  // },
];

export const TABLE_OPENED_POSITIONS_COLUMNS_NAMES = [
  {
    Header: <FormattedMessage id="table.th.account" defaultMessage="Account" />,
    accessor: 'account',
  },
  {
    Header: <FormattedMessage id="table.th.side" defaultMessage="Side" />,
    accessor: 'side',
  },
  {
    Header: <FormattedMessage id="table.th.position" defaultMessage="Position" />,
    accessor: 'position',
  },
  {
    Header: <FormattedMessage id="table.th.symbol" defaultMessage="Symbol" />,
    accessor: 'symbol',
  },
  {
    Header: <FormattedMessage id="table.th.openPrice" defaultMessage="Open Price" />,
    accessor: 'open_price',
  },
  {
    Header: <FormattedMessage id="table.th.currentPrice" defaultMessage="Current price" />,
    accessor: 'current_price',
  },
  {
    Header: <FormattedMessage id="table.th.volume" defaultMessage="volume" />,
    accessor: 'volume',
  },
  {
    Header: <FormattedMessage id="table.th.profit" defaultMessage="Profit" />,
    accessor: 'profit',
  },
  {
    Header: <FormattedMessage id="table.th.openTime" defaultMessage="Open Time" />,
    accessor: 'open_time',
  },
];
export const TABLE_DEALS_COLUMNS_NAMES = [
  {
    Header: <FormattedMessage id="table.th.account" defaultMessage="Account" />,
    accessor: 'account',
  },
  {
    Header: <FormattedMessage id="table.th.side" defaultMessage="Side" />,
    accessor: 'side',
  },
  {
    Header: <FormattedMessage id="table.th.deal" defaultMessage="Deal" />,
    accessor: 'deal',
  },
  {
    Header: <FormattedMessage id="table.th.symbol" defaultMessage="Symbol" />,
    accessor: 'symbol',
  },
  {
    Header: <FormattedMessage id="table.th.openPrice" defaultMessage="Open Price" />,
    accessor: 'open_price',
  },
  {
    Header: <FormattedMessage id="table.th.closePrice" defaultMessage="Close Price" />,
    accessor: 'close_price',
  },
  {
    Header: <FormattedMessage id="table.th.volume" defaultMessage="volume" />,
    accessor: 'volume',
  },
  {
    Header: <FormattedMessage id="table.th.profit" defaultMessage="Profit" />,
    accessor: 'profit',
  },
  {
    Header: <FormattedMessage id="table.th.closeTime" defaultMessage="Close Time" />,
    accessor: 'close_time',
  },
];

