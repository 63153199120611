import React, { useEffect, useRef } from 'react';
import './filter.scss';
import { Col, Row } from 'react-bootstrap';
import { ArrowsClockwise } from 'phosphor-react';
import { useForm } from 'react-hook-form';
import AccountSelect from '../../../../components/ui/AccountSelect/AccountSelect';
import Button from '../../../../components/ui/Button/Button';
import DatePickerRange from '../../../../components/ui/DatePickerRange/DatePickerRange';
import { FormattedMessage, useIntl } from 'react-intl';
import { getTimesTamp } from "../../../../utils/getTimesTamp";

const TradingHistoryFilters = ({ onSubmit, reloadFilters, tradingAccountsOpt }) => {
  const intl = useIntl();
  const componentMounted = useRef(true);

  const getDefaultValue = () => {
    return {
      open: '',
      trading_account: '',
      close: '',
    };
  };
  const { handleSubmit, reset, watch, setValue, getValues, control, formState } = useForm({
    reValidateMode: 'onChange',
    defaultValues: getDefaultValue(),
  });
  const { dirtyFields } = formState;

  const submit = (data) => {
    const trading_account = data['trading_account']?.value !== 'any'
      ? data['trading_account']?.value : null;
    const open = data['open'] ? getTimesTamp(data['open']) : null;
    const close = data['close'] ? getTimesTamp(data['close']) : null;

    onSubmit({
      trading_account,
      open,
      close,
    });
  };

  const resetForm = () => {
    onSubmit(null);
    reset(getDefaultValue());
  };

  useEffect(() => {
    return () => {
      componentMounted.current = false;
    };
  }, []);

  const isFormChange = () => {
    return Object.keys(dirtyFields).length;
  };

  return (
    <div className={'trading-history__filter'}>
      <form onSubmit={handleSubmit(submit)} className={'filters'}>
        <Row>
          <Col md={4}>
            <AccountSelect
              name="trading_account"
              control={control}
              title={
                <FormattedMessage
                  id="page.trading.history.tradingAccount.title"
                  defaultMessage="Trading account"
                />
              }
              accountsData={tradingAccountsOpt}
              controlPadding="3px 5px"
            />
          </Col>
          <Col md={4}>
            <div className={'datapicker'}>
              <DatePickerRange
                name="open"
                control={control}
                label={
                  <FormattedMessage
                    id="page.trading.history.range.open.title"
                    defaultMessage="Open time range"
                  />
                }
                placeholder={intl.formatMessage({
                  id: 'page.trading.history.range.placeholder',
                  defaultMessage: 'From - To',
                })}
              />
            </div>
          </Col>
          <Col md={4}>
            <div className={'datapicker'}>
              <DatePickerRange
                control={control}
                name="close"
                label={
                  <FormattedMessage
                    id="page.trading.history.range.close.title"
                    defaultMessage="Close time range"
                  />
                }
                placeholder={intl.formatMessage({
                  id: 'page.trading.history.range.placeholder',
                  defaultMessage: 'From - To',
                })}
              />
            </div>
          </Col>
        </Row>
      </form>

      <Row>
        <Col md={12}>
          <div className={'filters__btns'}>
            <Button
              className={reloadFilters ? 'reload-btn reload' : 'reload-btn'}
              icon={<ArrowsClockwise color="var(--white)" size={18} />}
              onClick={(e) => {
                e.preventDefault();
                submit(getValues());
              }}
            />
            <Button
              buttonText={
                <FormattedMessage
                  id='personalInfo.form.field.btn.reset'
                  defaultMessage='Reset'
                />
              }
              type="reset"
              onClick={() => resetForm()}
              disabled={!isFormChange()}
            />
            <Button
              buttonText={
                <FormattedMessage
                  id='page.trading.history.form.apply'
                  defaultMessage='Apply'
                />
              }
              type="submit"
              onClick={handleSubmit(submit)}
              disabled={!isFormChange()}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default TradingHistoryFilters;
