import API from '../api'
export const getPaymentsInfo = () => API.get('api/v1/settings')

export const fetchPayment = (data) => API.post('api/v1/payment/create', data)
export const createTransaction = (data) => API.post('api/v1/payment/transaction/create', data)

export const createTransactionKasha = (data) => API.post('api/v1/payment/kasha/transaction/create', data)

export const currencyKashaPayments = () => API.get(`/api/v1/payment/kasha/currency/list`);
export const getPaymentById = (id) => API.get(`api/v1/payment/info/${id}`)
export const getPayments = (options) => API.post(`api/v1/payment/list?${options}`)

