import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './verifyEmail.scss';
import LangSwitcher from '../../../components/ui/LangSwitcher';
import Button from '../../../components/ui/Button';
import { CheckCircle, SmileySad } from 'phosphor-react';
import { verificationEmail } from '../../../api/auth';
import { notify } from '../../../utils/notify';
import Preloader from '../../../components/ui/Preloader/Preloader';
import { FormattedMessage } from 'react-intl';
import { useSelector } from "react-redux";
import { getCompanyName } from "../../../utils/selectors";

const VerifyEmail = () => {
  const url: string[] = window.location.href.split('/');
  const email: string = url[4];
  const verificationCode: string = url[5];
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [verificateStatus, setVerificateStatus] = useState<boolean>(false);
  const companyName = useSelector(getCompanyName);

  const sendVerificationDate = (): void => {
    verificationEmail({ email: email, code: verificationCode })
      .then((res): void => {
        if (res.status === 200) {
          setIsLoading(false);
          setVerificateStatus(true);
          notify({
            type: 'success',
            message: res,
            timeOut: 4000,
          });
          setTimeout((): void => {
            navigate('/');
          }, 1000);
        }
      })
      .catch((error): void => {
        if (error && error.response !== null) {
          setIsLoading(false);
          notify({
            type: 'error',
            message: error.response,
            timeOut: 3000,
          });
        }
      });
  };

  useEffect((): void => {
    sendVerificationDate();
  }, []);

  return (
    <div className={'verify-email__page'}>
      <div className={'page'}>
        <div className={'page__box'}>
          {isLoading ? (
            <Preloader />
          ) : (
            <>
              <div className={'page__box-header'}>
                <div className={'page__box-header-icon'}>
                  {verificateStatus ? (
                    <CheckCircle size={132} />
                  ) : (
                    <SmileySad color="#f6222e" size={132} />
                  )}
                </div>
                <div className={'page__box-lang-switcher'}>
                  <LangSwitcher />
                </div>
              </div>
              <div className={'page__box-content text-page'}>
                {verificateStatus ? (
                  <p className={'title'}>
                    <FormattedMessage
                      id="page.verify.successfully"
                      defaultMessage="Registration completed successfully"
                    />
                  </p>
                ) : (
                  <p>
                    <FormattedMessage
                      id="page.verify.somethingwWong"
                      defaultMessage="Something wrong"
                    />
                  </p>
                )}
              </div>
              <div className={'page__box-footer'}>
                {verificateStatus ? (
                  // <Link to="/" className={'btn'}>
                  //   <FormattedMessage id="page.verify.SignIn" defaultMessage="Sign in" />
                  // </Link>
                  <Button
                    buttonText={
                      <FormattedMessage id="page.verify.SignIn" defaultMessage="Sign in" />
                    }
                    onClick={() => navigate('/')}
                  />
                ) : (
                  <Button
                    buttonText={
                      <FormattedMessage id="page.verify.tryAgain" defaultMessage="Try again" />
                    }
                    onClick={() => sendVerificationDate()}
                  />
                )}
              </div>
            </>
          )}
        </div>
      </div>
      <div className={'copyright'}>
        © {new Date().getFullYear()} {companyName}, Inc. All rights Reserved
      </div>
    </div>
  );
};

export default VerifyEmail;
