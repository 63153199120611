import React, { FC } from 'react';

import './error-page.scss';
import Page from '../Page/Page';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

interface IErrorPage {
  isPrivateRoute: boolean,
}

const ErrorPage: FC<IErrorPage> = ({ isPrivateRoute }) => {
  return (
    <Page>
      <div className={'error-page'}>
        {!isPrivateRoute && (
          <div className={'error-page__logo'}>
            <i></i>
          </div>
        )}
        <div className={'error-page__img'}></div>
        <div className={'error-page__description'}>
          <span className={'error-page__title'}>
            <FormattedMessage id="errorPage.title" defaultMessage="Page not found" />
          </span>
          <span className={'error-page__text'}>
            <FormattedMessage
              id="errorPage.text"
              defaultMessage="The page you are looking for does not exist, it may have been moved or deleted altogether. Please go to the main page by clicking the button below."
            />
          </span>
        </div>
        <div className={'error-page__link'}>
          {!isPrivateRoute ? (
            <>
              <Link to="/" className={'link-text'}>
                <FormattedMessage id="errorPage.login" defaultMessage="Log in" />
              </Link>
            </>
          ) : (
            <Link to="/finance" className={'link-text'}>
              <FormattedMessage id="errorPage.home" defaultMessage="Home" />
            </Link>
          )}
        </div>
      </div>
    </Page>
  );
};

export default ErrorPage;
