import React from "react";
import Preloader from "../../../../components/ui/Preloader";
import './ChatMessagesEmpty.scss';

type ChatMessagesEmptyProps = {
    hasMessages: boolean,
    messagesLoading: boolean,
    hasThread: boolean,
    threadsLoading: boolean
};

export const ChatMessagesEmpty = ({ hasMessages, messagesLoading, hasThread, threadsLoading }: ChatMessagesEmptyProps) => {
    return (
        <div className="chat-messages-empty">
            {((hasThread && messagesLoading) || threadsLoading) ? <Preloader /> : null}
            {(hasThread && !hasMessages && !messagesLoading) ? (
                <>
                    <div className='chat-empty-title'>There are no messages yet.</div>
                    <div className='chat-empty-title'>Be the first to say hello.</div>
                </>
            ): null}
            {(!hasThread && !threadsLoading) ? <div className="chat-empty-title">You don't have a support manager yet.</div> : null}
        </div>
    )
}