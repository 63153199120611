import React, { useEffect, useContext } from 'react';
import Select from 'react-select';
import './langSwitcher.scss';
import { langOpt, IT, CZ, JA, PT, TR, DE, RO } from '../../../constants/lang';
import { Context } from '../../LangWrapper';
import { useWindowWidth } from '@react-hook/window-size';
import { MOBILE_SIZE_WIDTH } from '../../../constants/mobile';
import { ITS_WITH_ITALIAN, ITS_WITH_CZECH, ITS_WITH_JAPANESE, ITS_WITH_PORTUGUESE, ITS_WITH_TURKISH, ITS_WITH_DEUTSCH, ITS_WITH_ROMANIAN } from '../../../config';

const SingleValue = ({ data, isDisabled, className, ...props }) => {
  return (
    <div
      className={`
        single-value
        ${isDisabled ? 'single-value--is-disabled' : ''}
        ${className ? className : ''}
        `
      }
    >
      <span className={'single-value__container'}>
        <span className={'single-value__icon'}>
          <img src={`${'data:image/svg+xml;base64'}, ${data.icon}`} alt="" />
        </span>
        <span className={'single-value__text'}>{data.text}</span>
      </span>
    </div>
  );
};

const CustomOption = (props) => {
  const { innerProps, innerRef, isDisabled } = props;
  return !isDisabled ? (
    <div ref={innerRef} {...innerProps} className={'option'}>
      <span className={'option__icon'}>
        <img src={`${'data:image/svg+xml;base64'}, ${props.data.icon}`} alt="" />
      </span>
      <span className={'option__label'}>{props.data.label}</span>
    </div>
  ) : null;
};

const LangSwitcher = ({ mobileStyles }) => {
  const width = useWindowWidth();
  const isMobile = () => width < MOBILE_SIZE_WIDTH;
  const getDefaultLang = () => {
    const currentLang = localStorage.getItem('lang');

    if (currentLang !== null) {
      return JSON.parse(currentLang);
    } else {
      return langOpt[0];
    }
  };

  const context = useContext(Context);

  const customStyleMobile = {
    control: (styles) => ({
      ...styles,
      backgroundColor: 'transparent',
      border: 'none',
      outline: 'none',
      boxShadow: 'none',
      paddingRight: '5px',
      display: 'flex',
      alignItems: 'center',
    }),
    indicatorsContainer: (styles) => ({
      ...styles,
      display: 'flex',
      alignItems: 'center',
      '> div': {
        padding: '8px 0 8px 0',
        '& svg': {
          fill: 'var(--secondary-text-color)',
        },
      },
      ...mobileStyles?.indicatorsContainer,
    }),
    indicatorSeparator: (styles) => ({ ...styles, display: 'none' }),
    singleValue: (styles) => ({
      ...styles,
      display: 'flex',
      alignItems: 'center',
      padding: '2px 0 2px 8px',
      color: 'var(--secondary-text-color)',
      '& .text': {
        paddingLeft: '5px',
        color: 'var(--white)',
      },
    }),
    valueContainer: (styles) => ({
      ...styles,
      // padding: '2px 0 2px 8px',
      padding: 0,
      height: '25px',
      display: 'flex',
      color: 'var(--secondary-text-color)',
      ...mobileStyles?.valueContainer,
    }),
    option: (styles) => ({
      ...styles,
      display: 'flex',
      alignItems: 'center',
      backgroundColor: 'transparent',
      '& .text': {
        paddingLeft: '5px',
      },
      '&:hover': {
        backgroundColor: 'var(--purple-100)',
      },
      '&:focus': {
        backgroundColor: 'var(--purple-100)',
      },
      '&:visited': {
        backgroundColor: 'var(--purple-100)',
      },
      '&:active': {
        backgroundColor: 'var(--purple-100)',
      },
    }),
    menuList: (styles) => ({
      ...styles,
      zIndex: 100,
    }),
    menu: (styles) => ({
      ...styles,
      zIndex: 100,
      width: '130px',
      right: '-20px',
    }),
  };

  const customStyles = {
    control: (styles) => ({
      ...styles,
      backgroundColor: 'transparent',
      border: 'none',
      outline: 'none',
      boxShadow: 'none',
      paddingRight: '5px',
      display: 'flex',
      alignItems: 'center',
    }),
    indicatorsContainer: (styles) => ({
      ...styles,
      display: 'flex',
      alignItems: 'center',
      '> div': {
        padding: '8px 0 8px 0',
        '& svg': {
          // marginTop: '5px',
          fill: 'var(--secondary-text-color)',
        },
      },
    }),
    indicatorSeparator: (styles) => ({ ...styles, display: 'none' }),
    singleValue: (styles) => ({
      ...styles,
      display: 'flex',
      alignItems: 'center',
      padding: '2px 0 2px 8px',
      color: 'var(--secondary-text-color)',
      '& .text': {
        paddingLeft: '5px',
      },
    }),
    valueContainer: (styles) => ({
      ...styles,
      padding: 0,
      height: '25px',
      display: 'flex',
    }),
    option: (styles) => ({
      ...styles,
      display: 'flex',
      alignItems: 'center',
      backgroundColor: 'transparent',
      '& .text': {
        paddingLeft: '5px',
      },
      '&:hover': {
        backgroundColor: 'var(--purple-100)',
      },
      '&:focus': {
        backgroundColor: 'var(--purple-100)',
      },
      '&:visited': {
        backgroundColor: 'var(--purple-100)',
      },
      '&:active': {
        backgroundColor: 'var(--purple-100)',
      },
    }),
    menuList: (styles) => ({
      ...styles,
      zIndex: 100,
    }),
    menu: (styles) => ({
      ...styles,
      zIndex: 100,
      width: '130px',
      right: '-20px',
    }),
  };

  useEffect(() => {
    getDefaultLang();
  }, []);

  return (
    <div className={'lang-switcher'}>
      <Select
        options={[ 
          ...langOpt, 
          ITS_WITH_ITALIAN && IT, 
          ITS_WITH_CZECH && CZ,
          ITS_WITH_JAPANESE && JA,
          ITS_WITH_PORTUGUESE && PT,
          ITS_WITH_TURKISH && TR,
          ITS_WITH_DEUTSCH && DE,
          ITS_WITH_ROMANIAN && RO
        ].filter(Boolean)}        
        components={{ SingleValue, Option: CustomOption }}
        defaultValue={getDefaultLang}
        styles={isMobile() ? customStyleMobile : customStyles}
        isSearchable={false}
        hideSelectedOptions={true}
        onChange={context.selectLang}
      />
    </div>
  );
};
export default LangSwitcher;
