import React, { useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from "react-intl";
import { getPayments } from "../../../api/payments-currency";
import ControlsGroup from '../../../components/ui/ControlsGroup';
import TablePagination from '../../../components/ui/TablePagination/TablePagination';
import { ITableLink, ITableMeta } from "../../../types/table";
import { DashboardLayout } from "../../UserView/DashboardLayout";
import Page from "../../Page/Page";
import BankTransfersStatusesTable
  from "../../../components/ui/Table/BankTransfersStatusesTable/BankTransfersStatusesTable";

const PaymentSystemStatuses = () => {
  const componentMounted = useRef(false);
  const [wireTransfers, setWireTransfers] = useState([]);
  const [tableDataLoading, setTableDataLoading] = useState(true);
  const [tableMeta, setTableMeta] = useState<Partial<ITableMeta>>({});
  const [tableLinks, setTableLinks] = useState<Partial<ITableLink>>({});
  const [perPageCount, setPerPageCount] = useState<number | null>(null);
  const intl = useIntl();

  const changingTabTitle = () => {
    // const title = intl.formatMessage({
    //   id: 'trading.accounts.title',
    //   defaultMessage: 'Trading Accounts',
    // });
    const title = 'Bank wire statuses'
    document.title = title;
  };

  useEffect(() => {
    getTableData(null);
    changingTabTitle();
    componentMounted.current = true;

    return (): void => {
      componentMounted.current = false;
    };
  }, []);

  const getTableData = (options): void => {
    setTableDataLoading(true);

    getPayments(getTablePageParams(options))
      .then((res): void => {
        if (componentMounted.current) {
          setTableMeta(res.data.meta);
          setTableLinks(res.data.links);
          setPerPageCount(res.data.meta.per_page);
          setWireTransfers(res.data.data);
        }
      })
      .catch((err): void => {
        console.log(err)
      })
      .finally(() => setTableDataLoading(false))
  };

  const getTablePageParams = (options) => {
    if (!options) {
      return '';
    }

    const { page, navigate, perPage } = options;
    let params;
    params = `per_page=${perPage || perPageCount}`;

    if (page) {
      params = `${params}&page=${page}`;
    } else if (navigate) {
      let toPage;
      toPage = tableLinks[navigate].split('?').reverse()[0];
      params = `${toPage}&${params}`;
    }

    return params;
  };

  return (
    <DashboardLayout>
      <Page
        title={<FormattedMessage id="menu.paymentSystemStatuses" defaultMessage="Payment system statuses" />}
      >

        <ControlsGroup>
          <BankTransfersStatusesTable
            data={wireTransfers}
            perPage={wireTransfers?.length}
            showLoader={tableDataLoading}
          />
          {wireTransfers?.length ? (
            <TablePagination
              goToFirstPage={(): void => {
                getTableData({ navigate: 'first' });
              }}
              goToPrevPage={(): void => {
                getTableData({ navigate: 'prev' });
              }}
              goToNextPage={(): void => {
                getTableData({ navigate: 'next' });
              }}
              goToLastPage={(): void => {
                getTableData({ navigate: 'last' });
              }}
              currentPage={tableMeta?.current_page}
              pagesLength={tableMeta?.last_page}
              perPageChange={(value): void => {
                setPerPageCount(value);
                getTableData({ perPage: value });
              }}
              goToSelectedPage={(page): void => {
                getTableData({ page });
              }}
              isFirstPageDisable={(): boolean => tableMeta?.current_page === 1}
              isPrevPageDisable={() => !tableLinks?.prev}
              isNextPageDisable={() => !tableLinks?.next}
              isLastPageDisable={(): boolean => tableMeta?.current_page === tableMeta?.last_page}
            />
          ) : (
            ''
          )}
        </ControlsGroup>
      </Page>
    </DashboardLayout>
  );
};

export default PaymentSystemStatuses;
