import React from 'react';
import { FormattedMessage } from 'react-intl';
import styles from './access-restricted.module.scss';

const AccessRestricted = () => {
  return (
    <div className={styles.content}>
      <FormattedMessage
        id="auth.siteIsRestricted"
        defaultMessage="Access to this site is restricted"
      />
    </div>
  );
};

export default AccessRestricted;
