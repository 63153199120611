import { createAsyncThunk } from "@reduxjs/toolkit";
import {
    MessageTypesVerbose,
    ProcessedMessageMode,
    SendTextMessagePayload,
    UpdateChatUserInfoPayload,
} from "../../../types/Chat";
import {
    chatGetToken,
    chatLogin,
    getChatMessages,
    getChatMessagesPage, getChatThread,
    getChatThreads, sendDocumentMessage, sendImageMessage,
    sendTextMessage,
    updateChatUserInfo,
} from "../../../api/chat";
import { allowedMessageTypes, mapMessageApiField } from "../../../pages/Chat/constants";
import { makeTemporaryMessage } from "../../../pages/Chat/helpers/makeTemporaryMessage";
import { getFileExtension } from "../../../pages/Chat/helpers/getFileExtension";
import { getMessageFileConfig } from "../../../pages/Chat/helpers/getMessageFileConfig";
import {
    addPendingMessage, removeMessage,
    selectChatUser,
    selectProcessedMessage,
    selectProcessedMessageMode, setChatToken,
} from "./chatSlice";
import { notify } from "../../../utils/notify";

type SendTextProps = {
    threadId: string;
    body: string;
};

type SendFileProps = {
    threadId: string;
    file: File;
};

type GetNextMessagesProps = {
    threadId: string;
    nextPage: string;
};

export const chatAuth = createAsyncThunk('chat/login', async (_, { rejectWithValue, dispatch }) => {
    try {
        const response = await chatGetToken();
        const chatToken = await chatLogin(response.data.token);

        localStorage.setItem('chat-token', chatToken.data.token);

        dispatch(setChatToken(chatToken.data.token));
        return
    } catch (error) {
        return rejectWithValue(error.response.data.message);
    }
});

export const updateUserInfo = createAsyncThunk('chat/user', async (data: UpdateChatUserInfoPayload, { rejectWithValue }) => {
    try {
        const response = await updateChatUserInfo(data);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data.message);
    }
});

export const getThreads = createAsyncThunk('chat/threads', async (_, { rejectWithValue }) => {
    try {
        const response = await getChatThreads();
        if (response.data.data.length) {
            const unreadCount = response.data.data[0]?.resources?.latest_message?.system_message ? 0 : response.data.data[0].unread_count;
            return {
                threads: response.data.data,
                unreadCount,
            }
        }
        return { threads: [], unreadCount: 0 }
    } catch (error) {
        return rejectWithValue(error.response.data.message);
    }
});

export const incomingThread = createAsyncThunk('chat/thread', async (threadId: string, { rejectWithValue }) => {
    try {
        const response = await getChatThread(threadId);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data.message);
    }
});


export const getMessages = createAsyncThunk('chat/messages', async (threadId: string, { rejectWithValue }) => {
    try {
        const response = await getChatMessages(threadId);

        return {
            messages: response.data.resources.messages.data.filter(message => allowedMessageTypes.includes(message.type_verbose)),
            next: response.data.resources.messages.meta.next_page_id,
            online: (response.data.resources?.participants?.data || []).reduce((result, participant) => {
                result[participant.owner_id] = participant.owner.options.online_status
                return result;
            }, {})
        };
    } catch (error) {
        notifyError(error);
        return rejectWithValue(error.response.data.message);
    }
});

export const getNextMessages = createAsyncThunk('chat/messages/page', async ({threadId, nextPage}: GetNextMessagesProps, { rejectWithValue }) => {
    try {
        const response = await getChatMessagesPage(threadId, nextPage);
        return {
            messages: response.data.data.filter(message => allowedMessageTypes.includes(message.type_verbose)),
            next: response.data.meta.next_page_id
        };
    } catch (error) {
        return rejectWithValue(error.response.data.message);
    }
});

export const sendText = createAsyncThunk('chat/messages/send/text',
    async ({ threadId, body }: SendTextProps, { rejectWithValue, dispatch, getState }) => {
        const state = getState();
        const user = selectChatUser(state);
        const { temporaryId, temporaryMessage } = makeTemporaryMessage(body, user.provider, MessageTypesVerbose.MESSAGE);

        try {
            await dispatch(addPendingMessage(temporaryMessage));

            const data: SendTextMessagePayload = {
                message: body,
                temporary_id: temporaryId
            };

            const processedMessageMode = selectProcessedMessageMode(state);
            if (processedMessageMode === ProcessedMessageMode.IS_REPLYING) {
                const processedMessage = selectProcessedMessage(state);
                data.reply_to_id = processedMessage.id
            }

            const response = await sendTextMessage(threadId, data);
            return response.data;
        } catch (error) {
            handleRejectedMessage(temporaryId, threadId, dispatch);
            return rejectWithValue(error.response.data.message);
        }
    });

export const sendFile = createAsyncThunk('chat/messages/send/file',
    async ({ threadId, file }: SendFileProps, { rejectWithValue, dispatch, getState }) => {
        const state = getState();
        const user = selectChatUser(state);
        const fileExtension = getFileExtension(file.name);
        const { type } = getMessageFileConfig(fileExtension);
        const { temporaryId, temporaryMessage } = makeTemporaryMessage(file.name, user.provider, type);

        try {
            await dispatch(addPendingMessage(temporaryMessage));

            const data = new FormData();
            data.append("temporary_id", temporaryId);
            data.append(mapMessageApiField[type], file, file.name);

            const processedMessageMode = selectProcessedMessageMode(state);
            if (processedMessageMode === ProcessedMessageMode.IS_REPLYING) {
                const processedMessage = selectProcessedMessage(state);
                data.append("reply_to_id", processedMessage.id);
            }

            const apiFn = type === MessageTypesVerbose.IMAGE_MESSAGE ? sendImageMessage : sendDocumentMessage;
            // @ts-ignore
            // TODO: CHAT - fix ts
            const response = await apiFn(threadId, data);
            return response.data;
        } catch (error) {
            handleRejectedMessage(temporaryId, threadId, dispatch);
            return rejectWithValue(error.response.data.message);
        }
    });

function notifyError(error){
    if (error.response.status === 403) {
        notify({
            type: 'error',
            message: error.response.data.message,
            timeOut: 3000,
        });
    }
}

function handleRejectedMessage(id, threadId, dispatch) {
    dispatch(removeMessage({ message_id: id, thread_id: threadId }))
    notify({
        type: 'error',
        message: 'Failed to send a message!',
        timeOut: 3000,
    });
}