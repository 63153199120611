import React, { useEffect, useRef, useState } from 'react';
import './history.scss';
import { DashboardLayout } from '../../UserView/DashboardLayout';
import Page from '../../Page/Page';
import ControlsGroup from '../../../components/ui/ControlsGroup/ControlsGroup';
import TradingTable from '../../../components/ui/Table/TradingTable/TradingTable';
import { tradingHistoryList } from '../../../api/trading';
import TablePagination from '../../../components/ui/TablePagination/TablePagination';
import { getTradingAccounts } from '../../../api/trading-account';
import { FormattedMessage, useIntl } from 'react-intl';
import TradingHistoryFilters from './TradingHistoryFilters/TradingHistoryFilters';

const History = () => {
  const title = 'Trading History';
  const [tradingAccounts, setTradingAccounts] = useState(null);
  const [isListLoading, setIsListLoading] = useState(true);

  const [filters, setFilters] = useState(null);

  const [tableData, setTableData] = useState([]);
  const [tableMeta, setTableMeta] = useState(null);
  const [tableLinks, setTableLinks] = useState(null);
  const [perPageCount, setPerPageCount] = useState(null);
  const componentMounted = useRef(true);
  const intl = useIntl();

  const changingTabTitle = () => {
    const title = intl.formatMessage({
      id: 'page.trading.history.title',
      defaultMessage: 'Trading History',
    });
    document.title = title;
  };

  useEffect(() => {
    getTradingAccounts()
      .then((res) => {
        if (componentMounted.current) {
          const accList = res.data.data;
          setTradingAccounts(accList);
        }
      })
      .catch((err) => console.log(err));
    getTradingList(null, null);
    changingTabTitle();

    return () => {
      componentMounted.current = false;
    };
  }, []);

  const getTradingList = (options, filter) => {
    setIsListLoading(true);
    tradingHistoryList(getTablePageParams(options, filter))
      .then((res) => {
        if (componentMounted.current && !res.errors) {
          setTableData(res.data.data);
          setTableMeta(res.data.meta);
          setTableLinks(res.data.links);
          setPerPageCount(res.data.meta.per_page);
          setIsListLoading(false);
        }
      })
      .catch((err) => {
        setIsListLoading(false);
      });
  };

  const getTablePageParams = (options, filter) => {
    if (!options) {
      return '';
    }

    const { page, navigate, perPage, trading_account } = options;
    let params;
    let perPageParams = `per_page=${perPage || perPageCount}`;
    params = perPageParams;

    if (page) {
      params = `${params}?&page=${page}`;
    } else if (navigate) {
      let toPage;
      toPage = tableLinks[navigate].split('?').reverse()[0];
      params = `${toPage}&${params}`;
    }

    if (filter) {
      let withFilters = `?${params}`;
      if (filter && filter.trading_account) {
        withFilters = `${withFilters}&trading_account=${filter.trading_account}`;
      }
      if (filter && filter.open) {
        withFilters = `${withFilters}&open[from]=${filter.open.from * 1000}&open[to]=${filter.open.to * 1000}`;
      }
      if (filter && filter.close) {
        withFilters = `${withFilters}&close[from]=${filter.close.from * 1000}&close[to]=${filter.close.to * 1000}`;
      }
      return withFilters;
    }

    return params;
  };

  return (
    <DashboardLayout>
      <Page
        title={
          <FormattedMessage id="page.trading.history.title" defaultMessage="Trading Accounts" />
        }
      >
        <ControlsGroup>
          <TradingHistoryFilters
            onSubmit={(data) => {
              if (!filters && !data) {
                return;
              }

              if (filters && !data) {
                getTradingList({ navigate: 'first' }, null);
                setFilters(null);
              } else {
                setFilters(data);
                getTradingList({ navigate: 'first' }, data);
              }
            }}
            reloadFilters={isListLoading}
            tradingAccountsOpt={tradingAccounts}
          />

          <div className={'trading-table'}>
            <TradingTable
              data={tableData}
              perPage={tableMeta?.per_page}
              showLoader={isListLoading}
              modifyDataHandler={(callback) => setTableData(tableData.map(callback))}
            />
            <TablePagination
              goToFirstPage={() => {
                getTradingList({ navigate: 'first' }, filters);
              }}
              goToPrevPage={() => {
                getTradingList({ navigate: 'prev' }, filters);
              }}
              goToNextPage={() => {
                getTradingList({ navigate: 'next' }, filters);
              }}
              goToLastPage={() => {
                getTradingList({ navigate: 'last' }, filters);
              }}
              currentPage={tableMeta?.current_page}
              pagesLength={tableMeta?.last_page}
              perPageChange={(value) => {
                setPerPageCount(value);
                getTradingList({ perPage: value }, filters);
              }}
              goToSelectedPage={(page) => {
                getTradingList({ page }, filters);
              }}
              isFirstPageDisable={() => tableMeta?.current_page === 1}
              isPrevPageDisable={() => !tableLinks?.prev}
              isNextPageDisable={() => !tableLinks?.next}
              isLastPageDisable={() => tableMeta?.current_page === tableMeta?.last_page}
            />
          </div>
        </ControlsGroup>
      </Page>
    </DashboardLayout>
  );
};

export default History;
