import { v4 as uuidv4 } from 'uuid';
import { escapeHtml } from "./unscapeHtml";

export const makeTemporaryMessage = (body, user, type) => {
    const temporaryId = uuidv4();
    return {
        temporaryId,
        temporaryMessage: {
            id: temporaryId,
            owner_id: user.base.id,
            owner_type: "users",
            owner: user,
            type_verbose: type,
            body: escapeHtml(body),
            created_at: new Date().toString(),
            temporary_id: temporaryId
        }
    }
};