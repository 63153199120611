import React, { useEffect } from 'react';

import './referral.scss';
import { DashboardLayout } from '../UserView/DashboardLayout';
import Page from '../Page/Page';
import { useIntl } from 'react-intl';

const Referral = () => {
  const title = 'Referral Program';
  const intl = useIntl();
  const changingTabTitle = () => {
    document.title = intl.formatMessage({
      id: 'page.referall.title',
      defaultMessage: 'Referral Program',
    });
  };

  useEffect(() => {
    changingTabTitle();
  }, []);

  return (
    <DashboardLayout>
      <Page title={title}></Page>
    </DashboardLayout>
  );
};

export default Referral;
