import { createSlice} from '@reduxjs/toolkit'

const langListSlice = createSlice({
    name: 'langList',
    initialState: {
        langList: [],
        currentLang: null,
    },
    reducers: {
        getLangList(state, action) {
            state.langList = action.payload
        },
        setCurrentLang(state, action) {
            state.currentLang = action.payload
        }
    }
})

export const {getLangList, setCurrentLang} = langListSlice.actions;

export default langListSlice.reducer;
