import React from "react";
import { selectThread } from "../../../../store/slice/chat/chatSlice";
import { useAppDispatch, useAppSelector } from "../../../../store";
import { ChatMessageForm } from "../ChatMessageForm/ChatMessageForm";
import { getThreads } from "../../../../store/slice/chat/thunks";
import { MessagesContainer } from "./components/MessagesContainer";
import { handleReadThread } from "../../helpers/readThread";
import { loadThreadsInterval } from "../../constants";
import usePolling from "../../../../hooks/usePolling";
import './ChatDialog.scss';

export const ChatDialog = () => {
    const dispatch = useAppDispatch();
    const support = useAppSelector(selectThread);

    usePolling(() => dispatch(getThreads()), loadThreadsInterval);

    return (
        <div className="chat-dialog-page" onClick={() => handleReadThread(support)}>
            <div className="dialog-header">Live Support Chat</div>
            <MessagesContainer />
            {support ? <ChatMessageForm /> : null}
        </div>
    )
}