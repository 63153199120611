export const unEscapeHtml = (text: string = '') => {
    const unescaped = {
        '&amp;'  : '&',
        '&#38;'  : '&',
        '&#038;' : '&',
        '&#x26;' : '&',
        '&lt;'   : '<',
        '&#60;'  : '<',
        '&#x3C;' : '<',
        '&gt;'   : '>',
        '&#62;'  : '>',
        '&#x3E;' : '>',
        '&quot;' : '"',
        '&#34;'  : '"',
        '&#x22;' : '"',
        '&apos;' : '\'',
        '&#039;' : '\'',
        '&#39;'  : '\'',
        '&#x27;' : '\''
    };

    return text.replace(
            /&(?:amp|#038|#x26|lt|#60|#x3C|gt|#62|#x3E|apos|#039|#x27|quot|#34|#x22);/g,
        (match) => unescaped[match]
    )
};

export const escapeHtml = (text: string = '') => {
    const escaped = {
        '&' : '&amp;',
        '<' : '&lt;',
        '>' : '&gt;',
        '"' : '&quot;',
        '\'': '&#039;'
    };

    return text.replace(/[&<>"']/g, (match) => escaped[match])
};
