import { ReactNode } from "react";
import { mapMessageFiles } from "../constants";
import { MessageTypesVerbose } from "../../../types/Chat";

type GetMessageFileConfig = (string) => {
    icon: string | ReactNode,
    type: MessageTypesVerbose
};

export const getMessageFileConfig: GetMessageFileConfig = (extension) => {
    return mapMessageFiles[extension] || {
        icon: '',
        type: MessageTypesVerbose.DOCUMENT_MESSAGE
    }
}