import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import Button from '../Button/Button';
import './amount.scss';
import { useIntl } from 'react-intl';

const Amount = (props) => {
  const { currency, buttons = [], label, onValueChange, required, val } = props;
  const intl = useIntl();
  const placeholder = intl.formatMessage({
    id: 'page.finance.deposit.popup.field.amount.title',
    defaultMessage: 'Amount',
  });
  const [value, setValue] = useState('');
  useEffect(() => {
    setValue(val);
  }, [val]);
  const modifyValue = (value) => {
    setValue(value);
    onValueChange(value);
  };
  const increaceHandler = () => {
    const result = !value ? 1 : value + 1;
    setValue(result);
    onValueChange(result);
  };
  const decreaceHandler = () => {
    const result = value ? value - 1 : 0;
    setValue(result);
    onValueChange(result);
  };

  const renderButtons = () => {
    return buttons.map((item, pos) => (
      <Button
        key={pos}
        buttonText={item}
        disabled={props.disabled}
        size="big"
        onClick={modifyValue.bind(undefined, item)}
      />
    ));
  };

  return (
    <div className={'amount'}>
      <span className={'amount__label'}>{label}</span>
      <div className={'amount__wrap'}>
        <div className={`amount__input ${props.error ? 'error' : ''}`}>
          <div className={'amount__currency'}>{currency || 'USD'}</div>
          <input
            disabled={props.disabled}
            type="number"
            min={0}
            placeholder={`${placeholder}${required ? ' *' : ''}`}
            value={value}
            onChange={(e) => {
              let value = Number(e.target.value);
              if (value === 0) {
                e.target.value = '';
              }
              if (value < 0) {
                value = 0;
              }
              e.target.value = value;
              setValue(value);
              onValueChange(value);
            }}
          />
          <div className={`counters ${props.disabled ? 'disabled' : ''}`}>
            <i className={'increace'} onClick={increaceHandler}></i>
            <i className={'decreace'} onClick={decreaceHandler}></i>
          </div>
        </div>
        {buttons && buttons.length && <div className={'amount__buttons'}>{renderButtons()}</div>}
      </div>
      {props.details && <p className="details">{props.details}</p>}
      {props.error && <p className={'errorName'}>{props.error}</p>}
    </div>
  );
};

export default Amount;
